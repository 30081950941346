import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./ServicePage.scss";
import roadimg from "../../../Images/Service/road.png";
import car from "../../../Images/Service/car.png";
import itembox from "../../../Images/Service/service-item.png";
import { HiArrowLongRight } from "react-icons/hi2";
import { FaTools } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { GiTowTruck, GiBattery75, GiFlatTire } from "react-icons/gi";
import { TbDiscount2 } from "react-icons/tb";
import { AiFillCar } from "react-icons/ai";
import { MdSupportAgent } from "react-icons/md";
import backImg from "../../../Images/Service/page-back.png";

import pageImg1 from "../../../Images/SomeofScreen/img3.jpg";
import pageImg2 from "../../../Images/SomeofScreen/img4.jpg";
import pageImg3 from "../../../Images/SomeofScreen/img5.jpg";
import pageImg4 from "../../../Images/SomeofScreen/img6.jpg";
import pageImg5 from "../../../Images/SomeofScreen/img7.jpg";
import pageImg6 from "../../../Images/SomeofScreen/img8.jpg";
import pageImg7 from "../../../Images/SomeofScreen/img9.jpg";
import pageImg8 from "../../../Images/SomeofScreen/img10.jpg";
import { Storage } from "../../../App";

const boxitem = [
  {
    id: 1,
    icon: <FaTools className="icons" />,
    head: "Roadside Assistance",
    tag: `On The ^ Spot Repairing`,
    pera: "Roadserve offers comprehensive 24x7 roadside assistance to help you overcome unexpected car troubles. Whether you have a",
    classIs: "icon1",
    img: pageImg1,
    link: "assistance",
  },
  {
    id: 2,
    icon: <IoMdSettings className="icons" />,
    head: "Fuel Delivery",
    tag: `On The ^ Spot Repairing`,
    pera: "Running out of fuel is an inconvenience that can leave you stranded. Roadserve offers fuel delivery services to",
    classIs: "icon2",
    img: pageImg2,
    link: "fuel",
  },
  {
    id: 3,
    icon: <GiTowTruck className="icons" />,
    head: "Towing Services",
    tag: `On The ^ Spot Repairing`,
    pera: "In the event of a major breakdown or accident, Roadserve offers reliable towing services. Our professional towing partners will",
    classIs: "icon3",
    img: pageImg3,
    link: "towing",
  },
  {
    id: 4,
    icon: <GiBattery75 className="icons" />,
    head: "Battery Jumpstart",
    tag: `On The ^ Spot Repairing`,
    pera: "If your car's battery dies and you're unable to start your vehicle, Roadserve offers battery jumpstart services. Our technicians",
    classIs: "icon4",
    img: pageImg4,
    link: "battery",
  },
  {
    id: 5,
    icon: <GiFlatTire className="icons" />,
    head: "Flat Tire Assistance",
    tag: `On The ^ Spot Repairing`,
    pera: "Dealing with a flat tire can be frustrating, especially when you're on the go. Roadserve provides tire assistance, including tire",
    classIs: "icon5",
    img: pageImg5,
    link: "tire",
  },
  {
    id: 6,
    icon: <TbDiscount2 className="icons" />,
    head: "Discounted Parts",
    tag: `On The ^ Spot Repairing`,
    pera: "As a valued Roadserve customer, you gain access to exclusive discounts on a wide range of car parts and accessories. Enjoy",
    classIs: "icon6",
    img: pageImg6,
    link: "discounted",
  },
  {
    id: 7,
    icon: <AiFillCar className="icons" />,
    head: "Car or Suv",
    tag: `On The ^ Spot Repairing`,
    pera: "Roadserve makes it easy for you to get the expert auto assistance you need. Whether you drive a car or a SUV, we offer",
    classIs: "icon7",
    img: pageImg7,
    link: "car",
  },
  {
    id: 8,
    icon: <MdSupportAgent className="icons" />,
    head: "Dedicated Support",
    tag: `On The ^ Spot Repairing`,
    pera: "Our team is equipped with expert vehicle technicians and helpful customer service agents, who are highly trained in their profession",
    classIs: "icon8",
    img: pageImg8,
    link: "support",
  },
];

function ServicePage() {
  const navigate = useNavigate();
  const { getServiceId } = useContext(Storage);
  const [focusIs, setFocusIs] = useState(0);
  const [onWay, setOnWay] = useState();

  useEffect(() => {
    const screenIs = window.innerWidth;
    if (screenIs > 1440) {
      if (focusIs === 0) {
        setOnWay("5%");
      } else if (focusIs === 1) {
        setOnWay("17%");
      } else if (focusIs === 2) {
        setOnWay("29%");
      } else if (focusIs === 3) {
        setOnWay("41%");
      } else if (focusIs === 4) {
        setOnWay("53%");
      } else if (focusIs === 5) {
        setOnWay("64%");
      } else if (focusIs === 6) {
        setOnWay("76%");
      } else if (focusIs === 7) {
        setOnWay("88%");
      }
    } else if (screenIs > 1280 && screenIs <= 1440) {
      if (focusIs === 0) {
        setOnWay("8%");
      } else if (focusIs === 1) {
        setOnWay("19%");
      } else if (focusIs === 2) {
        setOnWay("30%");
      } else if (focusIs === 3) {
        setOnWay("41%");
      } else if (focusIs === 4) {
        setOnWay("52%");
      } else if (focusIs === 5) {
        setOnWay("63%");
      } else if (focusIs === 6) {
        setOnWay("74%");
      } else if (focusIs === 7) {
        setOnWay("85%");
      }
    } else if (screenIs > 925 && screenIs <= 1280) {
      if (focusIs === 0) {
        setOnWay("5%");
      } else if (focusIs === 1) {
        setOnWay("17%");
      } else if (focusIs === 2) {
        setOnWay("29%");
      } else if (focusIs === 3) {
        setOnWay("40%");
      } else if (focusIs === 4) {
        setOnWay("52%");
      } else if (focusIs === 5) {
        setOnWay("64%");
      } else if (focusIs === 6) {
        setOnWay("76%");
      } else if (focusIs === 7) {
        setOnWay("88%");
      }
    } else if (screenIs > 768 && screenIs <= 925) {
      if (focusIs === 0) {
        setOnWay("7%");
      } else if (focusIs === 1) {
        setOnWay("18%");
      } else if (focusIs === 2) {
        setOnWay("29%");
      } else if (focusIs === 3) {
        setOnWay("40%");
      } else if (focusIs === 4) {
        setOnWay("50%");
      } else if (focusIs === 5) {
        setOnWay("62%");
      } else if (focusIs === 6) {
        setOnWay("72%");
      } else if (focusIs === 7) {
        setOnWay("83%");
      }
    } else if (screenIs <= 768) {
      if (focusIs === 0) {
        setOnWay("7%");
      } else if (focusIs === 1) {
        setOnWay("18%");
      } else if (focusIs === 2) {
        setOnWay("29%");
      } else if (focusIs === 3) {
        setOnWay("40%");
      } else if (focusIs === 4) {
        setOnWay("50%");
      } else if (focusIs === 5) {
        setOnWay("62%");
      } else if (focusIs === 6) {
        setOnWay("72%");
      } else if (focusIs === 7) {
        setOnWay("83%");
      }
    }
  }, [focusIs]);

  function auto_scroll(event) {
    const mainDiv = document.getElementById("item");
    let scrollIs = event.currentTarget.scrollTop;
    const screenIs = window.innerWidth;
    // console.log(scrollIs)
    if (screenIs > 1440) {
      if (scrollIs > 0 && scrollIs < 350) {
        setFocusIs(0);
      } else if (scrollIs > 350 && scrollIs < 1050) {
        setFocusIs(1);
      } else if (scrollIs > 1050 && scrollIs < 1850) {
        setFocusIs(2);
      } else if (scrollIs > 1850 && scrollIs < 2750) {
        setFocusIs(3);
      } else if (scrollIs > 2750 && scrollIs < 3450) {
        setFocusIs(4);
      } else if (scrollIs > 3450 && scrollIs < 4250) {
        setFocusIs(5);
      } else if (scrollIs > 4250 && scrollIs < 5150) {
        setFocusIs(6);
      } else if (scrollIs > 5150) {
        setFocusIs(7);
      }
    } else if (screenIs > 800 && screenIs <= 1440) {
      if (scrollIs > 0 && scrollIs < 350) {
        setFocusIs(0);
      } else if (scrollIs > 350 && scrollIs <= 1150) {
        setFocusIs(1);
      } else if (scrollIs > 1150 && scrollIs <= 1800) {
        setFocusIs(2);
      } else if (scrollIs > 1800 && scrollIs <= 2380) {
        setFocusIs(3);
      } else if (scrollIs > 2380 && scrollIs <= 3000) {
        setFocusIs(4);
      } else if (scrollIs > 3000 && scrollIs <= 3580) {
        setFocusIs(5);
      } else if (scrollIs > 3580 && scrollIs < 4300) {
        setFocusIs(6);
      } else if (scrollIs >= 4300) {
        setFocusIs(7);
      }
    } else if (screenIs >  425 && screenIs <= 800) {
      if (scrollIs > 0 && scrollIs < 350) {
        setFocusIs(0);
      } else if (scrollIs > 350 && scrollIs <= 900) {
        setFocusIs(1);
      } else if (scrollIs > 900 && scrollIs <= 1400) {
        setFocusIs(2);
      } else if (scrollIs > 1400 && scrollIs <= 2100) {
        setFocusIs(3);
      } else if (scrollIs > 2100 && scrollIs <= 2600) {
        setFocusIs(4);
      } else if (scrollIs > 2600 && scrollIs <= 3100) {
        setFocusIs(5);
      } else if (scrollIs > 3100 && scrollIs < 3700) {
        setFocusIs(6);
      } else if (scrollIs >= 3700) {
        setFocusIs(7);
      }
    } else if (screenIs <= 425) {
      if (scrollIs > 0 && scrollIs < 350) {
        setFocusIs(0);
      } else if (scrollIs > 350 && scrollIs <= 735) {
        setFocusIs(1);
      } else if (scrollIs > 734 && scrollIs <= 1250) {
        setFocusIs(2);
      } else if (scrollIs > 1250 && scrollIs <= 1730) {
        setFocusIs(3);
      } else if (scrollIs > 1730 && scrollIs <= 2160) {
        setFocusIs(4);
      } else if (scrollIs > 2160 && scrollIs <= 2670) {
        setFocusIs(5);
      } else if (scrollIs > 2670 && scrollIs < 3200) {
        setFocusIs(6);
      } else if (scrollIs >= 3200) {
        setFocusIs(7);
      }
    } 
  }

  const service_click = (index) => {
    const mainDiv = document.getElementById("item");
    const screenIs = window.innerWidth;
    setFocusIs(index);
    if (screenIs > 1600) {
      if (index === 0) {
        mainDiv.scrollTo(0, 0);
      } else if (index === 1) {
        mainDiv.scrollTo(0, 800);
      } else if (index === 2) {
        mainDiv.scrollTo(0, 1630);
      } else if (index === 3) {
        mainDiv.scrollTo(0, 2450);
      } else if (index === 4) {
        mainDiv.scrollTo(0, 3250);
      } else if (index === 5) {
        mainDiv.scrollTo(0, 4050);
      } else if (index === 6) {
        mainDiv.scrollTo(0, 4900);
      } else if (index === 7) {
        mainDiv.scrollTo(0, 5700);
      }
    } else if (screenIs > 1440 && screenIs <= 1600) {
      if (index === 0) {
        mainDiv.scrollTo(0, 0);
      } else if (index === 1) {
        mainDiv.scrollTo(0, 770);
      } else if (index === 2) {
        mainDiv.scrollTo(0, 1590);
      } else if (index === 3) {
        mainDiv.scrollTo(0, 2350);
      } else if (index === 4) {
        mainDiv.scrollTo(0, 3100);
      } else if (index === 5) {
        mainDiv.scrollTo(0, 3900);
      } else if (index === 6) {
        mainDiv.scrollTo(0, 4700);
      } else if (index === 7) {
        mainDiv.scrollTo(0, 5625);
      }
    } else if (screenIs > 1280 && screenIs <= 1440) {
      if (index === 0) {
        mainDiv.scrollTo(0, 0);
      } else if (index === 1) {
        mainDiv.scrollTo(0, 740);
      } else if (index === 2) {
        mainDiv.scrollTo(0, 1530);
      } else if (index === 3) {
        mainDiv.scrollTo(0, 2320);
      } else if (index === 4) {
        mainDiv.scrollTo(0, 3050);
      } else if (index === 5) {
        mainDiv.scrollTo(0, 3850);
      } else if (index === 6) {
        mainDiv.scrollTo(0, 4630);
      } else if (index === 7) {
        mainDiv.scrollTo(0, 5625);
      }
    } else if (screenIs > 1080 && screenIs <= 1280) {
      if (index === 0) {
        mainDiv.scrollTo(0, 0);
      } else if (index === 1) {
        mainDiv.scrollTo(0, 680);
      } else if (index === 2) {
        mainDiv.scrollTo(0, 1350);
      } else if (index === 3) {
        mainDiv.scrollTo(0, 2020);
      } else if (index === 4) {
        mainDiv.scrollTo(0, 2700);
      } else if (index === 5) {
        mainDiv.scrollTo(0, 3400);
      } else if (index === 6) {
        mainDiv.scrollTo(0, 4050);
      } else if (index === 7) {
        mainDiv.scrollTo(0, 5625);
      }
    } else if (screenIs > 768 && screenIs <= 1080) {
      if (index === 0) {
        mainDiv.scrollTo(0, 0);
      } else if (index === 1) {
        mainDiv.scrollTo(0, 600);
      } else if (index === 2) {
        mainDiv.scrollTo(0, 1220);
      } else if (index === 3) {
        mainDiv.scrollTo(0, 1860);
      } else if (index === 4) {
        mainDiv.scrollTo(0, 2500);
      } else if (index === 5) {
        mainDiv.scrollTo(0, 3110);
      } else if (index === 6) {
        mainDiv.scrollTo(0, 3750);
      } else if (index === 7) {
        mainDiv.scrollTo(0, 4400);
      }
    } else if (screenIs > 600 && screenIs <= 768) {
      if (index === 0) {
        mainDiv.scrollTo(0, 0);
      } else if (index === 1) {
        mainDiv.scrollTo(0, 580);
      } else if (index === 2) {
        mainDiv.scrollTo(0, 1150);
      } else if (index === 3) {
        mainDiv.scrollTo(0, 1760);
      } else if (index === 4) {
        mainDiv.scrollTo(0, 2350);
      } else if (index === 5) {
        mainDiv.scrollTo(0, 2910);
      } else if (index === 6) {
        mainDiv.scrollTo(0, 3500);
      } else if (index === 7) {
        mainDiv.scrollTo(0, 4400);
      }
    } else if (screenIs > 425 && screenIs <= 600) {
      if (index === 0) {
        mainDiv.scrollTo(0, 0);
      } else if (index === 1) {
        mainDiv.scrollTo(0, 550);
      } else if (index === 2) {
        mainDiv.scrollTo(0, 1070);
      } else if (index === 3) {
        mainDiv.scrollTo(0, 1600);
      } else if (index === 4) {
        mainDiv.scrollTo(0, 2150);
      } else if (index === 5) {
        mainDiv.scrollTo(0, 2710);
      } else if (index === 6) {
        mainDiv.scrollTo(0, 3200);
      } else if (index === 7) {
        mainDiv.scrollTo(0, 4400);
      }
    } else if (screenIs > 0 && screenIs < 425) {
      if (index === 0) {
        mainDiv.scrollTo(0, 0);
      } else if (index === 1) {
        mainDiv.scrollTo(0, 500);
      } else if (index === 2) {
        mainDiv.scrollTo(0, 1000);
      } else if (index === 3) {
        mainDiv.scrollTo(0, 1470);
      } else if (index === 4) {
        mainDiv.scrollTo(0, 1950);
      } else if (index === 5) {
        mainDiv.scrollTo(0, 2440);
      } else if (index === 6) {
        mainDiv.scrollTo(0, 2900);
      } else if (index === 7) {
        mainDiv.scrollTo(0, 4400);
      }
    }
  };

  return (
    <div
      className="service-scrolling-components"
      id="service-scrolling-components"
    >
      <div className="scroll-container">
        <div className="left-container">
          <div className="road-track">
            <img src={roadimg} alt="Best Roadside Assistance In Pune" className="roadimg" />
            <img
              src={car}
              alt="Best Car Repair Near Me Mumbai"
              className="car-img"
              id="car"
              style={{ top: onWay }}
            />
          </div>
          <div className="service-items-container">
            {boxitem.map((item, index) => (
              <div className="item-box" key={index}>
                {focusIs === index ? (
                  <img src={itembox} alt="Car Road Service Near Me Ahmedabad" className="item-focus" />
                ) : null}
                <div
                  className={`items ${focusIs === index ? "focus" : null}`}
                  onClick={() => {
                    service_click(index);
                  }}
                >
                  {item.icon} <p className="head">{item.head}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className="right-container"
          onScroll={(event) => auto_scroll(event)}
          id="item"
        >
          {boxitem.map((items, index) => (
            <div className="fast-box" key={index} id={`box${index}`}>
              <div className="left-box">
                <img src={backImg} alt="My Car Roadside Assistance In Amritsar" className="backImg" />
                <img src={items.img} alt="On Road Car Assistance In Bhopal" className="pageImg" />
              </div>
              <div className="right-box">
                <div className="contents">
                  <p className="head">Our Services</p>
                  <h3 className="tags">{items.head}</h3>
                  <p className="para">{items.pera}...</p>
                  <button
                    className="button"
                    onClick={() => {
                      getServiceId(items.id - 1);
                      navigate("/service");
                    }}
                  >
                    Learn More <HiArrowLongRight class="btn-icon" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ServicePage;
