import React, { useEffect, useState } from "react";
import "../Scss/ThankYou.scss";
import { FcOk } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
function ThankYou() {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(6);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(interval);
        navigate('/');
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <div className="thankyou-components">
      <div className="thankyou-container">
        <FcOk className="icons" />
        <h3 className="header">Thank You</h3>
        <p className="para">
          Thank you for contacting Roadserve. Our representative will get back to you shortly.
        </p>

        <p className="message">
          This page will be redirected automatically, If it does not redirect after {seconds} seconds, <span className="button" onClick={() => navigate('/')}> Click Here </span>
        </p>

      </div>
    </div>
  );
}

export default ThankYou;
