import { CoustomerItems } from "../../../Datas/NavbarData";
import "../../Scss/Navbar.scss";
import { useState } from "react";
import { Link } from "react-router-dom";
import React, {useContext} from "react";
import { Storage } from "../../../App";
function CustomerNavbar() {
  const {getPagename, postPagename} = useContext(Storage);

  return (
    <>
      <div className="customer-navbar-mobile">
        <div className="customer-menus">
          {CoustomerItems.map((items) => (
            <Link
              to={items.link}
              key={items.id}
              className={`links ${
                postPagename === items.name ? "active" : "none"
              }`}
              onClick={() => getPagename(items.name)}
              id={items.name}
            >
              {items.icon} <span className="item">{items.name}</span>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export default CustomerNavbar;
