import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { AddCat_URL } from "../../../API/API";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
function createNotification(type) {
  switch (type) {
    case 'info':
      NotificationManager.info('Info message');
      break;
    case 'success':
      NotificationManager.success('Success message', 'added Successfully', 3000);
      break;
    case 'warning':
      NotificationManager.warning('Warning message', 'Enter Valid Data', 3000);
      break;
    case 'error':
      NotificationManager.error('Error message', 'Sorry! Try Again', 3000);
      break;
  }
};

const schema = yup.object({
  category: yup.string().required(),
}).required();


function AddCategory() {
  const { register, handleSubmit, reset } = useForm({ resolver: yupResolver(schema) });
  const [spinner, setSpinner] = useState(false);

  const onSubmit = (data) => {
    const token = localStorage.getItem("admin_token");
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    }
    setSpinner(true);
    axios.post(`${AddCat_URL}${data.category}`, config)
      .then((res) => {
        createNotification('success')
        setSpinner(false);
        reset();
      })
      .catch((err) => {
        console.log("err :", err)
        createNotification('error')
      })
  }

  return (
    <>
      <form className="form-container-box" onSubmit={handleSubmit(onSubmit)}>
        <Form.Control size="lg" type="text" placeholder="Blog Category" {...register("category")} name="category" className="inputdiv"/>
        {
          spinner ? (
            <Button variant="primary" type="submit" className="btndiv">Category Adding...</Button>
          ) : (
            <Button variant="primary" type="submit" className="btndiv">Add Category</Button>
          )
        }

      </form>
      <NotificationContainer />
    </>
  )
}

export default AddCategory;