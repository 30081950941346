import React, { useState, useEffect, useContext } from "react";
import "./Blog.scss";
import backhead from "../../../Images/Background/Pricing-head.png";
import { AiOutlineHome } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Category from "./Container/Category";
import BlogContainer from "./Container/BlogContainer";
import Footer from "../../Components/Footer/Footer";
import Pagination from "./Container/PaginationComponents";
import AppContainer from "./Container/AppContainer";
import axios from "axios";
import Loder from '../../../Loder/Loder';
import {
  Blog_URL,
  Category_URL,
  CatSort_URL,
  TagSort_URL,
} from "../../../API/API";
import { Storage } from "../../../App";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Helmet } from "react-helmet";

function Blog() {
  const navigate = useNavigate();
  const { getcategory, getBlogItem, postCatLog, getCatLog, posttagId } = useContext(Storage);
  const [spinner, setSpinner] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const page_handler = (value) => {
    setPage(value);
  };

  function getAllBlogApi() {
    setSpinner(true);
    axios.get(`${Blog_URL}page=${page}&limit=12`)
      .then((res) => {
        getBlogItem(res.data.data);
        setTotalPage(res.data.total_pages);
        setSpinner(false);
      })
      .catch((err) => {
        console.log("User Blog Error :", err);
      });
  }

  function getCategoryApi() {
    axios
      .get(Category_URL)
      .then((res) => {
        getcategory(res.data);
      })
      .catch((err) => {
        console.log("User Category error:", err);
      });
  }

  function getCatSortApi() {
    setSpinner(true);
    axios
      .get(`${CatSort_URL}${postCatLog}&page=${page}&limit=12`)
      .then((res) => {
        if (res.data.data.length >= 1) {
          setTotalPage(res.data.total_pages);
          getBlogItem(res.data.data);
        } else {
          Swal.fire('Sorry, there are no blog founds')
          getCatLog("All");
        }
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
        console.log("Category Filter error : ", err);
      });
  }

  function getTagSortApi() {
    setSpinner(true);
    axios
      .get(`${TagSort_URL}${posttagId}&page=${page}&limit=12`)
      .then((res) => {
        if (res.data.data.length >= 1) {
          setTotalPage(res.data.total_pages);
          getBlogItem(res.data.data);
        } else {
          Swal.fire('Sorry, there are no blog founds')
          getCatLog("All")
        }
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
        console.log("Tag Filter err : ", err);
      });
  }

  useEffect(() => {
    if (postCatLog === "All") {
      getAllBlogApi();
    } else {
      getCatSortApi();
    }
  }, [postCatLog]);

  useEffect(() => {
    getAllBlogApi();
    getCategoryApi();
  }, []);

  useEffect(() => {
    getAllBlogApi();
  }, [page]);

  useEffect(() => {
    if (posttagId) {
      getTagSortApi();
    }
  }, [posttagId]);

  return (
    <div className="blog-container" style={{ minHeight: "80vh", justifyContent: "center" }}>
      <Helmet>
        <title>24/7 Breakdown Service In Mumbai | Best Car Repair Near Me Mumbai</title>
        <meta name="description" content="Want a 24 Breakdown Service In Mumbai? Team Rapid Reaction is available to assist you! For prompt assistance, give us a call at +18003093431. We'll quickly get you back behind the wheel." />
        <meta name='keywords' content="24/7 Breakdown Service In Mumbai, Best Car Repair Near Me Mumbai, Car Breakdown Service In Mumbai, Car Maintenance Near Me Mumbai, Car Mechanic Near Me 24 Hours In Mumbai" />
        <meta name='keyphrase' content="Car Breakdown Service In Mumbai, Car Maintenance Near Me Mumbai" />
        <meta name='keyphrase' description="Reliable and prompt Car Breakdown Service In Mumbai. To help you swiftly get back on the road, we provide towing, 24/7 support, and on-the-spot repairs. For prompt assistance, give us a call." />
        <meta name="serp-rank" position="1" />
        <meta name="description" content="Ridcor" />
        <meta name="keywords" content="Ridcor" />
        <meta name="revisit-after" content="7 days" />
        <meta name='Author' content="https://roadserve.in" />
        <meta name='Copyright' content="Road Serve" />
        <meta name='rating' content="general" />
        <meta name='distribution' content="global" />
        <meta property="og:title" content="Car Maintenance Near Me Mumbai, Best Car Repair Near Me Mumbai" />
        <meta property='og:type' content="website" />
        <meta property="og:description" content="In search of Car Maintenance Near Me Mumbai? Look for trustworthy vehicle repair in your area. We can take care of anything, from repairs to routine maintenance. Schedule a meeting right now." />
        <meta property="og:image" content=" https://roadserve.in/static/media/Pricing-head.39dd28f605126e03ef8d.png" />
        <meta property="og:url" content="https://roadserve.in" />
        <link rel="canonical" href="https://roadserve.in" />
        <meta property="og:site_name" content="Road Serve" />
        <meta itemprop='name' content="Best Car Repair Near Me Mumbai, Car Mechanic Near Me 24 Hours In Mumbai" />
        <meta itemprop='description' content="Visit Mumbai Auto Care in your neighbourhood for the Best Car Repair Near Me Mumbai. Their highly skilled experts provide excellent service and high-quality repairs." />
        <meta itemprop='url' content="https://roadserve.in" />
      </Helmet>
      {spinner ? (
        <Loder />
      ) : (
        <>
          <div className="header-container">
            <img src={backhead} alt="Emergency Roadside Assistance In Delhi" className="backhead" />
            <div className="about-heading-text">
              <h3 className="head-text">
                Want to listen our <br /> roadside stories
              </h3>
              <div className="page-name-links">
                <AiOutlineHome className="icon" />
                <p onClick={() => navigate("/")}>Home / </p>
                <p>Blog</p>
              </div>
            </div>
          </div>
          <Category />
          <BlogContainer />
          <Pagination
            getPage={page_handler}
            postPage={page}
            totalPage={totalPage}
          />
          <AppContainer />
          <Footer />
        </>
      )}
    </div>
  );
}

export default Blog;
