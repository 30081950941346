import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import './ChooseMethod.scss';

function ChooseMethod() {
  const [method, setMethod] = useState(false);
  const param = useParams();
  const plan = param.plan.split('=')[1];
  const navigate = useNavigate();

  const method_handler = (value) => {
    setMethod(value);
    console.log(value, plan);
  }

  const submit_handler = () => {
    navigate(`/plans/plan=${plan}/method=${method}`);
  }
  
  return (
    <div className="main-div">
      <div className='method-container'>
        <h3 className="heading">Choose Method</h3>
        <Form className='choose-method' onChange={(e) => method_handler(e.target.value)} >

          <Form.Check type='radio' name="group1" value="cod" label="COD" />
          <Form.Check type='radio' name="group1" value="prepaid" label="PREPAID" />

        </Form>
        {plan === 'ultimate' || plan === 'advance' && method === 'cod' ? (
          <div className="form-header">
            <p className="peragraph">Pay now to get on additional 300 rupees off & instant card assignment.</p>
          </div>
        ) : null}
        <button type='submit' className='btn' onClick={submit_handler}>Continue</button>
      </div>

    </div>
  )
}

export default ChooseMethod;