import React, { useContext, useEffect } from "react";
import { Storage } from "../../App";
import { useNavigate } from "react-router-dom";
import "../Scss/Policy.scss"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function PrivacyPolicyPage() {
  const navigate = useNavigate();
  const { postUserlog } = useContext(Storage);
  useEffect(() => {
    if (postUserlog) {
      navigate("/user/dashbord");
    }else{
      navigate("/");
    }
  }, []);


  return (
    <div className="policy-components">
      <ArrowBackIcon className="icons" onClick={ ()=> navigate('/') } />
      <div className="policy-container">

        <div className="policy-header-box">
          <p className="header">Policy</p>
          <h3 className="tags">App Privacy Policy</h3>
          <p className="pera">
            We take your privacy seriously, and we are committed to protecting
            your personal information. This Privacy Policy outlines how we
            collect, use, and share your personal data when you use our Roadside
            Assistance app ("Roadserve").
          </p>
        </div>

        <div className="policy-details">
          <div className="details-box">
            <h5 className="details-heading"><span></span>Information We Collect</h5>
            <p className="details-pera">
              When you use our app, we collect the following information:
            </p>
            <p className="details-pera">
              Personal Information: We collect your name, phone number, email
              address, and mailing address to provide you with our services. We
              may also collect your car details to better understand your needs.
            </p>
            <p className="details-pera">
              ocation Information: We collect your location to provide you with
              accurate roadside assistance services.
            </p>
            <p className="details-pera">
              Subscription Information: We collect your subscription information
              when you purchase a subscription plan to provide you with premium
              benefits.
            </p>
            <p className="details-pera">
              We may also collect other information that you voluntarily provide
              to us when you use our app.
            </p>
          </div>

          <div className="details-box">
            <h5 className="details-heading"><span></span>How We Use Your Information</h5>
            <p className="details-pera">
              We use your information to provide you with our roadside
              assistance services and to improve our app's functionality.
              Specifically, we use your information to:
            </p>
            <p className="details-pera">
              Provide you with roadside assistance services, including sending
              assistance to your location.
            </p>
            <p className="details-pera">
              Manage your subscription plan and provide you with premium
              benefits.
            </p>
            <p className="details-pera">
              Improve our app's functionality and user experience.
            </p>
            <p className="details-pera">
              Communicate with you regarding our services, such as responding to
              your inquiries or notifying you of any updates.
            </p>
            <p className="details-pera">
              Protect our app and its users from fraud, security threats, and
              other illegal activities.
            </p>
          </div>

          <div className="details-box">
            <h5 className="details-heading"><span></span>How We Share Your Information</h5>
            <p className="details-pera">
              We do not share your personal information with anyone outside of
              our company. We do not sell or rent your personal data to third
              parties, nor do we display any ads within the app.
            </p>
            <p className="details-pera">
              We may share your information with our third-party service
              providers who help us provide our services, such as payment
              processors or IT support. These service providers are
              contractually obligated to keep your information confidential and
              secure.
            </p>
            <p className="details-pera">
              We may also disclose your information to law enforcement agencies,
              government bodies, or other third parties as required by law or to
              protect our legal rights.
            </p>
          </div>

          <div className="details-box">
            <h5 className="details-heading"><span></span>Security</h5>
            <p className="details-pera">
              We take the security of your personal information seriously and
              have implemented measures to protect your data from unauthorized
              access, disclosure, or alteration. We use industry-standard
              encryption technology to secure your information and follow best
              practices to safeguard your data.
            </p>
          </div>

          <div className="details-box">
            <h5 className="details-heading"><span></span>In-App Purchases</h5>
            <p className="details-pera">
              Our app includes in-app purchases for subscription plans. If you
              make an in-app purchase, you will be directed to the appropriate
              app store (e.g. Google Play) to complete the transaction. We do
              not store any payment information on our servers.
            </p>
            <p className="details-pera">Changes to this Privacy Policy</p>
            <p className="details-pera">
              We reserve the right to modify this Privacy Policy at any time, so
              please review it periodically. If we make any material changes to
              this Privacy Policy, we will notify you through the app or by
              email.
            </p>
          </div>

          <div className="details-box">
            <h5 className="details-heading"><span></span>Contact Us</h5>
            <p className="details-pera">
              If you have any questions or concerns about this Privacy Policy,
              please contact us at support@roadserve.in
            </p>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default PrivacyPolicyPage;
