import React, { useContext } from "react";
import { LineWave } from  'react-loader-spinner'
import { Storage } from "../../../../App";

function Category() {
  const {postCatLog, getCatLog, postcategory} = useContext(Storage);
  return (
    <div className="category-components">
      <div className="cat-container">
        <h4 className="category-head">Category :-</h4>
        {postcategory.length > 0 ? (
          <div className="category-items">
            <p className={`item ${postCatLog === "All" ? "active" : null}`} onClick={() => getCatLog("All")}>All</p>
            {postcategory.map((item, index) => (
              <p className={`item ${postCatLog === item.category_id ? "active" : null}`} key={index} onClick={() => getCatLog(item.category_id)}>
                {item.name}
              </p>
            ))}
          </div>
        ) : (
          <LineWave
            height="100"
            width="100"
            color="#4fa94d"
            ariaLabel="line-wave"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            firstLineColor="red"
            middleLineColor="green"
            lastLineColor="blue"
          />
        )}
      </div>
      <div className="blank"></div>
    </div>
  );
}

export default Category;
