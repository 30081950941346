import React, { useEffect, useState } from 'react';
import './CheckStatus.scss';
import { Watch, ThreeDots } from 'react-loader-spinner';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { verifyDetails_URL, StorePayment_URL } from '../../../API/API';


function CheckStatus() {
    const navigate = useNavigate();
    const [refrash, setRefrash] = useState(false);
    const paymentData = JSON.parse(localStorage.getItem('Payment_data'));
    const coustomerData = JSON.parse(localStorage.getItem('coustomer_data'));
    const txn_tacking_id = localStorage.getItem('tracking_id');
    const bank_ref_no = localStorage.getItem('bank_ref_no');
    const payment_mode = localStorage.getItem('payment_mode');
    const order_status = localStorage.getItem('order_status');
    const Token = localStorage.getItem('token_plans_log');

    useEffect(() => {
        const config = {
            headers: {
                'Authorization': `Bearer ${Token}`
            }
        }

        if (coustomerData !== null) {
            const data = {
                name: coustomerData.name,
                contact_number: coustomerData.contact_number,
                address: coustomerData.address,
                pin_code: coustomerData.pin_code,
                state: coustomerData.state,
                car_number: coustomerData.car_number,
                car_model: coustomerData.car_model,
                plan: paymentData.plan,
                amount: paymentData.amount,
                coupon: "ultimate30",
                method: paymentData.method,
                id: coustomerData._id?.$oid,
            }

            axios.post(verifyDetails_URL, data, config)
                .then((res) => {
                    console.log('Detaild : ', res)
                    if (order_status === 'Success') {
                        if (res.data.order_present) {
                            const Payment_Data = {
                                order_id: res.data.order_id,
                                txn_tacking_id: txn_tacking_id,
                                bank_ref_no: bank_ref_no,
                                payment_mode: payment_mode,
                                status: true,
                            }
                            axios.post(StorePayment_URL, Payment_Data, config)
                                .then((res) => {
                                    navigate('/payment/status=true')
                                })
                                .catch((err) => {
                                    setRefrash(true);
                                    console.log('Order Store Error : ', err);
                                })
                        } else {
                            const Payment_Data = {
                                // order_id: res.data.order_id,
                                txn_tacking_id: txn_tacking_id,
                                bank_ref_no: bank_ref_no,
                                payment_mode: payment_mode,
                                status: true,
                            }
                            axios.post(StorePayment_URL, Payment_Data, config)
                                .then((res) => {
                                    navigate('/payment/status=true')
                                })
                                .catch((err) => {
                                    setRefrash(true);
                                    console.log('Order Store Error : ', err);
                                })
                        }



                    } else {
                        navigate('/payment/status=false')
                    }
                })
                .catch((err) => {
                    setRefrash(true)
                    console.log("Send Details Api Error : ", err)
                })

        }


    }, [])
    return (
        <div className='main-div'>
            {
                refrash ? (
                    <div className="checkstatus-components">
                        <h4 className='text'>Please Refresh The Browser</h4>
                        <p className='text'>If the issue still persists after refreshing & your balance has been deducted, don't worry. <br /> You can call us at our toll free number 18003093431</p>
                    </div>
                ) : (
                    <div className="checkstatus-components">
                        <Watch
                            height="200"
                            width="200"
                            radius="48"
                            color="#4fa94d"
                            ariaLabel="watch-loading"
                            visible={true}
                        />
                        <h3 className="text">Checking
                            <ThreeDots
                                height="50"
                                width="50"
                                radius="9"
                                color="#4fa94d"
                                ariaLabel="three-dots-loading"
                                visible={true}
                            />
                        </h3>
                    </div>
                )
            }

        </div >
    )
}

export default CheckStatus