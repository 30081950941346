import React,{useContext} from 'react';
import popimg from '../../Images/Background/otppopup.png';
import CancelIcon from '@mui/icons-material/Cancel';
import { Storage } from '../../App';
import { useNavigate } from 'react-router-dom';
function OTPPopUp() {
    const navigate = useNavigate();
    const{getpopup} = useContext(Storage);
  return (
    <div className="popup-components">
        <div className="popup-container">
            <div className="popup-box">
                <img src={popimg} alt="" />
                <h3>Your mechanic reach your location, please, accepted mechanic</h3>
                <h5>Your mechanic code Number is <h4>“9000”</h4></h5>
                <button  onClick={() => {
                    getpopup(null);
                    navigate('/user/cart')
                }}>Started</button>

                <CancelIcon className="cancel-btn" onClick={() => {
                    getpopup(null);
                    navigate('/user/cart');
                }}/>
            </div>
        </div>
    </div>
  )
}

export default OTPPopUp;