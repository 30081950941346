export const User_Token = "User-Token";
export const Verifi_Token = "Verifi-Token";
export const MechDetails = "Mech-Details"

export const Base_URL = "https://admin.roadserve.in/";

export const Login_URL = `${Base_URL}login?contact_number=`;
export const Verifi_OTP_URL = `${Base_URL}otp-verification?contact_number=`;
export const Registration_URL = `${Base_URL}registration`;
export const UserData_Url = `${Base_URL}user-data`;

export const Nearby_Mechanics_URL = `${Base_URL}nearby-mechanics`;
export const UpdateProfile_URL = `${Base_URL}update_user?`;


export const Subscribe_URL = `${Base_URL}subscribe?email=`;
export const SendMail_URL =`${Base_URL}send-mail/`;
export const Plans_URL = `${Base_URL}choose-plan/`;

// blog==========================================================================
export const BaseURL_SEC = "https://admin.roadserve.in/";
export const AdminRegister_URL = `${BaseURL_SEC}admin/register?`;
export const AdminLog_URL = `${BaseURL_SEC}admin/login?`
export const Blog_URL = `${BaseURL_SEC}get_post_list?`;
export const Category_URL = `${BaseURL_SEC}show_categories_blog`;
export const CatSort_URL = `${BaseURL_SEC}get_posts_by_category?category_id=`;
export const GetDetails_URL = `${BaseURL_SEC}get_post_detail?post_id=`;
export const GetTags_URL = `${BaseURL_SEC}show_tags_Blog`;
export const TagSort_URL = `${BaseURL_SEC}get_post_by_tags?tags_id=`;
export const GetCall_URL = `${BaseURL_SEC}get_call_back`;
export const EditBlog_URL = `${BaseURL_SEC}edit_post?post_id=`
export const DeleteBlog_URL = `${BaseURL_SEC}delete_post?post_id=`
export const AddImg_URL = `${BaseURL_SEC}add_images?post_id=`;
export const AddBlog_URL = `${BaseURL_SEC}add_post`;
export const AddTag_URL = `${BaseURL_SEC}add_tag_blog?name=`;
export const AddCat_URL = `${BaseURL_SEC}add_category_blog?name=`;
export const DeleteTag_URL = `${BaseURL_SEC}delete_tag_blog?tag_id=`;
export const DeleteCat_URL = `${BaseURL_SEC}delete_category_blog?category_id=`;

// plans===========================================================================
export const verifyNumber_URL = `${BaseURL_SEC}website-login`;
export const verifyOTP_URL = `${BaseURL_SEC}otp-verification_website?contact_number=`;
export const verifyCoupon_URL = `${BaseURL_SEC}`;
export const verifyDetails_URL = `${BaseURL_SEC}form-details-website`;
export const StorePayment_URL = `${BaseURL_SEC}store-payment_after_payment`;

export const Tracking_URL = `${BaseURL_SEC}search-excel-in-orders?orderid=`;