import React, { useContext, useEffect } from 'react';
import { Storage } from '../../App';
import { useNavigate } from 'react-router-dom';
import Service from '../PagesComponents/Service/Service';
import { Helmet } from "react-helmet";

function ServicesPage() {
  const navigate = useNavigate();
  const { postUserlog, getPage } = useContext(Storage);
  useEffect(() => {
    if (postUserlog) {
      navigate("/user/home");
    }
    getPage('Services')
  }, [])
  return (
    <>
      <Helmet>
        <title>Best Car Repair Near Me Mumbai | Best Mechanic Near Me Mumbai</title>
        <meta name="description" content="Discover the Best Car Repair Near Me Mumbai. Choose from a comprehensive selection of car services, including routine maintenance, car washes, customised repairs for accidents." />
        <meta name="keywords" content="best car repair near me Mumbai, best mechanic near me Mumbai, best roadside assistance company in Mumbai, best roadside assistance in Mumbai, car breakdown service in Mumbai" />
        <meta name='keyphrase' content="Car Mechanic Near Me Mumbai, Car Mechanic Near Me Home Service In Mumbai" />
        <meta name='keyphrase' content="Get a quick and reliable car mechanic near me in Mumbai that will far beyond your expectations. With just one click of the APP download, you can access all types of auto repair services." />
        <meta name="serp-rank" position="1" />
        <meta name="description" content="Ridcor" />
        <meta name="keywords" content="Ridcor" />
        <meta name="revisit-after" content="7 days" />
        <meta name='Author' content="https://roadserve.in" />
        <meta name='Copyright' content="Road Serve" />
        <meta name='rating' content="general" />
        <meta name='distribution' content="global" />
        <meta property="og:title" content="Hyundai Road Side Assistance In Mumbai, Near By Car Mechanic In Mumbai" />
        <meta property='og:type' content="website" />
        <meta property="og:description" content="Hyundai Roadside Assistance In Mumbai, the program offers 24 hour emergency assistance in the event of any mechanical or electrical failure. Call for the best service and download the APP right now." />
        <meta property="og:image" content="https://roadserve.in/static/media/Roadside.d1b8d2ff928d704cb66f.png" />
        <meta property="og:url" content="https://roadserve.in" />
        <link rel='canonical' href="https://roadserve.in" />
        <meta property="og:site_name" content="Road Serve" />
        <meta itemprop='name' content="Road Service Near Me In Mumbai, Road Side Assistance In Mumbai" />
        <meta itemprop='description' content="Using the Road Serve App, you may instantly access 24 hour road service near me in Mumbai from any location at any time. In addition, the services are available on demand." />
        <meta itemprop='url' content="https://roadserve.in" />
      </Helmet>
      <div className="hide-text" style={{ display: 'none' }}>
        <h1>Car Towing Service Near Me Ahmedabad</h1>
        <h1>Commercial Road Service Near Me Amritsar</h1>
        <h1>Emergency Roadside Assistance In Amritsar</h1>
        <h1>Honda Roadside Assistance In Amritsar</h1>
        <h1>Hyundai Road Side Assistance In Amritsar</h1>
        <h1>My Car Roadside Assistance In Amritsar</h1>
      </div>
      <Service />
    </>

  )
}

export default ServicesPage