import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { GetTags_URL, DeleteTag_URL } from '../../../API/API';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

function createNotification(type) {
  switch (type) {
    case 'info':
      NotificationManager.info('Info message');
      break;
    case 'success':
      NotificationManager.success('Success message', 'Delete Successfull', 3000);
      break;
    case 'warning':
      NotificationManager.warning('Warning message', 'Enter Valid Data', 3000);
      break;
    case 'error':
      NotificationManager.error('Error message', 'Sorry! Try Again', 3000);
      break;
  }
};

const schema = yup.object({
  tag: yup.string().required(),
}).required();


function DeleteTags() {

  const [tags, setTags] = useState(false);
  const token = localStorage.getItem("Admin_token");
  const { register, handleSubmit, reset } = useForm({ resolver: yupResolver(schema) });
  const [spinner, setSpinner] = useState(false);

  function callApis() {
    axios.get(GetTags_URL)
      .then((res) => {
        setTags(res.data)
      })
      .catch((err) => {
        console.log("err :", err);
      })
  }

  useEffect(() => {
    callApis();
  }, []);
  useEffect(() => {
    callApis();
  }, [spinner]);

  const onSubmit = (data) => {
    const token = localStorage.getItem("admin_token");
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    }
    setSpinner(true);

    axios.delete(`${DeleteTag_URL}${data.tag}`)
      .then((res) => {
        createNotification('success')
        setSpinner(false);
        reset();
      })
      .catch((err) => {
        console.log("err :", err)
        createNotification('error')
        setSpinner(false);
      })
  }

  return (
    <>
      <form className="form-container-box" onSubmit={handleSubmit(onSubmit)}>
        <Form.Select size="lg" {...register("tag")} name="tag" className="inputdiv">
          <option value="false">Select Tags</option>
          {
            tags.length > 0 ? (
              tags.map((item, index) => (
                <option value={item.tag_id} key={index}>{item.name}</option>
              ))
            ) : null
          }

        </Form.Select>
        {
          spinner ? (
            <Button variant="primary" type="submit" className="btndiv">Tag Deleting...</Button>
          ) : (
            <Button variant="primary" type="submit" className="btndiv">Delete Tag</Button>
          )
        }
      </form>
      <NotificationContainer />
    </>
  )
}

export default DeleteTags