import React from 'react';
import appback from "../../../../Images/Background/apps-back.png";
import { BsGooglePlay } from "react-icons/bs";

function AppContainer() {
  return (
    <div className="app-container">
        <img src={appback} alt="appback" className="appback" />
        <div className="app-container-box">
          <h3 className="head">
            Download Our App for <br /> Any Devices:
          </h3>
          <a href="https://play.google.com/store/apps/details?id=com.roadserve.roadservepanel" className="button">
            <BsGooglePlay className="icons" /> Download for Android
          </a>
        </div>
      </div>
  )
}

export default AppContainer