import React, { useContext, useEffect } from 'react';
import '../Scss/UserDashbord.scss'
import Home from '../Components/Home/Home'
import Works from '../Components/Works/Works';
import About from '../Components/About/About';
import Counter from '../Components/Counter/Counter';
import Testimonial from '../Components/Testimonial/Testimonial';
import Contact from '../Components/Contact/Contact';
import ServicePage from '../Components/Service/ServicePage';
import Footer from '../Components/Footer/Footer';
import { Storage } from '../../App';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";

// import Pricing from '../Components/Pricing/Pricing';
// import Services from '../Components/Services/Services';
// import Screens from '../Components/Screens/Screens';
// import Repairing from '../Components/Repairing/Repairing';


function UserDashbord() {
  const navigate = useNavigate();
  const { postUserlog, getPage } = useContext(Storage);
  useEffect(() => {
    if (postUserlog) {
      navigate('/user/home');
    } else {
      navigate('/');
    }
    getPage('Home')
  }, [])

  return (
    <div className="user-dashbord">
      <Helmet>
        <title>24/7 Roadside Assistance in Mumbai | 24/7 Breakdown Service in Mumbai</title>
        <meta name='description' content="The majority of our contracts offer 24 7 roadside assistance in Mumbai in the event that your car breaks down. Instead of utilizing an independent roadside assistance business." />
        <meta name='keywords' content="24 7 roadside assistance in Mumbai, 24 breakdown service in Mumbai, 24 7 roadside assistance near me Mumbai, 24x7 roadside assistance near me Mumbai, best car repair near me Mumbai" />
        <meta name='keyphrase' content="Car Breakdown Service Near Me Mumbai, Car Maintenance Near Me Mumbai" />
        <meta name="keyphrase" description="When you add Road Serve for Car Breakdown Service Near Me Mumbai, you're never too far from assistance if you get stuck on the road. Having access to a trustworthy service provider under these circumstances." />
        <meta name="serp-rank" position="1" />
        <meta name="description" content="Ridcor" />
        <meta name="keywords" content="Ridcor" />
        <meta name="revisit-after" content="7 days" />
        <meta name='Author' content="https://roadserve.in" />
        <meta name='Copyright' content="Road Serve" />
        <meta name='rating' content="general" />
        <meta name='distribution' content="global" />
        <meta property="og:title" content="Car Towing Service Near Me Mumbai, Commercial Road Service Near Me Mumbai" />
        <meta property='og:type' type content="website" />
        <meta property="og:description" content="For assistance with hitching, towing, and getting back on the road, our skilled towing providers performing Car Towing Service Near Me Mumbai are available to you around the clock, every day of the year." />
        <meta property="og:image" content=" https://roadserve.in/static/media/img1.a57e9b44dde1338a2091.png" />
        <meta property="og:url" content="https://roadserve.in" />
        <meta property="og:site_name" content="Road Serve" />
        <link rel='canonical' href="https://roadserve.in" />
        <meta itemprop='name' content="Best Laptop Repair In Mumbai, SSD Data Recovery In Mumbai" />
        <meta itemprop='description' content="At your house, Ruchi Infotech Services provides the best laptop repair in Mumbai. We provide comprehensive laptop and computer repair services throughout Mumbai. We fix computer screens." />
        <meta itemprop='url' content="https://roadserve.in" />
      </Helmet>

      <div className="hide-text" style={{ display: 'none' }}>
        <h1>Honda Roadside Assistance In Amritsar</h1>
        <h1>Hyundai Road Side Assistance In Amritsar</h1>
        <h1>My Car Roadside Assistance In Amritsar</h1>
        <h1>Near By Car Mechanic In Bhopal</h1>
        <h1>On Road Car Assistance In Bhopal</h1>
        <h1>On Road Car Service In Bhopal</h1>
        <h1>On Road Service Near Me Bhopal</h1>
        <h1>Recovery Van Near Me In Bhopal</h1>
      </div>

      <Home />
      <Works />
      <About />
      <Counter />
      <ServicePage />
      <Testimonial />
      <Contact />
      <Footer />
    </div>
  )
}

export default UserDashbord;