import React, { useState, useEffect } from "react";
import {
  AiOutlineHome,
  AiFillTwitterCircle,
  AiFillInstagram,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import backhead from "../../../Images/Background/contact-back.png";
import Footer from "../../Components/Footer/Footer";
import "./Contact.scss";
import { IoLocationSharp, IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { BsFacebook } from "react-icons/bs";
import followimg from "../../../Images/Background/followus.png";
import { SendMail_URL } from "../../../API/API";
import axios from "axios";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import mapImg from "../../../Images/Background/maps.png";
import { ThreeDots } from "react-loader-spinner";
const schema = yup
  .object({
    name: yup.string().required("Name is a required field"),
    email: yup.string().required("Email is a required field"),
    phone: yup.string().required("Number is a required field"),
    message: yup.string().required("Message is a required field"),
  })
  .required();

function Contact() {
  const navigate = useNavigate();
  const [canditionval, setConditionval] = useState(false);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = (data) => {
    if (canditionval) {
      if (data.phone.length === 10) {
        setLoading(true);
        axios
          .post(SendMail_URL, {
            name: data.name,
            phone: data.phone,
            email: data.email,
            subject: "New Contact",
            message: data.message,
          })
          .then((res) => {
            reset();
            setLoading(false);
            navigate("/thank-you");
          })
          .catch((err) => {
            console.log("Subscribe err : ", err);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: "try Again",
            });
          });
      }else{
        alert("Type Valid Mobile Number")
      }
    }
  };

  return (
    <div className="contact-container">
      <div className="header-container">
        <img src={backhead} alt="Car Breakdown Service In Pune" className="backhead" />
        <div className="about-heading-text">
          <h3 className="head-text">
            Let us know what <br />
            you need!
          </h3>
          <div className="page-name-links">
            <AiOutlineHome className="icon" />
            <p onClick={() => navigate("/")}>Home / </p>
            <p>Contact Us</p>
          </div>
        </div>
      </div>

      <div className="contact-container-box">
        <div className="contact-box">
          <div className="left-box">
            <p className="heads">Contact</p>
            <h3 className="headers">Get In Touch</h3>
            <p className="para">
              Leave your name, email, number and message we'll <br /> get right
              back to you.
            </p>

            <div className="details-box">
              <div className="items">
                <IoLocationSharp className="icons" />
                <div className="right">
                  <p className="head">Location</p>
                  <p className="tag">
                    3rd floor 2151/9b shadipur, patel nagar,
                    <br /> New Delhi, Pin- 110008.
                  </p>
                </div>
              </div>

              <div className="items">
                <IoCall className="icons" />
                <div className="right">
                  <p className="head">Call Us</p>
                  <p className="tag">+18003093431</p>
                </div>
              </div>

              <div className="items">
                <MdEmail className="icons" />
                <div className="right">
                  <p className="head">Email</p>
                  <p className="tag">support@roadserve.in</p>
                </div>
              </div>
            </div>
          </div>

          <div className="mid-box">
            <img src={followimg} alt="Emergency Roadside Assistance In Amritsar" className="followimg" />
            <div className="icons-link">
              <a href="https://www.facebook.com/RoadServeIndia" target="blank">
                <BsFacebook className="icon" />
              </a>
              <a
                href="https://www.instagram.com/roadserveindia/"
                target="blank"
              >
                <AiFillInstagram className="icon" />
              </a>
              <a href="https://twitter.com/RoadServeIndia" target="blank">
                <AiFillTwitterCircle className="icon" />
              </a>
            </div>
          </div>

          <form className="right-box" onSubmit={handleSubmit(onSubmit)}>
            <h3 className="heads">Let's Connect</h3>
            <div className="item-group">
              <input
                type="text"
                className="input"
                placeholder="Your Name"
                name="name"
                {...register("name")}
                required
              />
              <input
                type="email"
                className="input"
                placeholder="Your Email"
                name="email"
                {...register("email")}
                required
              />
            </div>

            <input
              type="number"
              className="input-box number"
              placeholder="Your Number"
              name="phone"
              {...register("phone")}
              required
            />

            <textarea
              className="message"
              placeholder="How can i help?"
              name="message"
              {...register("message")}
              required
            ></textarea>

            <div className="buttom-box">
              <div className="input-field-3">
                <label>
                  <input
                    type="checkbox"
                    value="option2"
                    name="conditions"
                    onChange={(e) => setConditionval(e.target.value)}
                  />
                  I want to recieve newsletters
                </label>
              </div>
              {loading ? (
                <button className="button-loder">
                  Sending
                  {
                    <ThreeDots
                      radius="3"
                      color="#fff"
                      visible={true}
                      height="20"
                      width="50"
                    />
                  }
                </button>
              ) : (
                <button className="button">Send Message</button>
              )}
            </div>
          </form>
        </div>
      </div>

      <div className="map-container">
        <img src={mapImg} alt="Emergency Roadside Assistance In Amritsar" className="mapImg" />
      </div>

      <Footer />
    </div>
  );
}

export default Contact;
