import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import img1 from '../../../../Images/SomeofScreen/img1.jpg';
import img2 from '../../../../Images/SomeofScreen/img2.jpg';
import img3 from '../../../../Images/SomeofScreen/img3.jpg';
import img4 from '../../../../Images/SomeofScreen/img4.jpg';
import img5 from '../../../../Images/SomeofScreen/img5.jpg';
import img6 from '../../../../Images/SomeofScreen/img6.jpg';
import img7 from '../../../../Images/SomeofScreen/img7.jpg';
import img8 from '../../../../Images/SomeofScreen/img8.jpg';
import img9 from '../../../../Images/SomeofScreen/img9.jpg';
import img10 from '../../../../Images/SomeofScreen/img10.jpg';


function Slider() {
    const [screens, setscreens] = useState([img1, img2, img3, img4, img5, img6, img7, img8, img9, img10])
  return (
    <div className="items-container">
      <Carousel className="slider">
        {screens.map((item, index) => (
          <Carousel.Item interval={2000} className="boxes">
            <img src={item} alt="img" className="screen" key={index}/>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default Slider;
