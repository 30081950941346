import React, { useEffect, useState } from 'react';
import './StatusPage.scss';
import { FcOk } from "react-icons/fc";
import { IoMdCloseCircle } from 'react-icons/io';
import { AiOutlineClose } from 'react-icons/ai';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
function StatusPage() {
    const params = useParams();
    const navigate = useNavigate();
    const [status, setStatus] = useState();

    useEffect(() => {
        if (params.status) {
            const sts = params.status.split('=')[1];
            if (sts === 'true') {
                setStatus(true)
            } else if (sts === 'false') {
                setStatus(false)
            } else {
                navigate('/')
            }
        }
    }, [])

    return (
        <>
            <Helmet>
                <script>
                    {window.gtag('event', 'conversion', {'send_to': 'AW-11204168724/T5f8CP7LluEYEJSYyN4p'})}
                </script>
            </Helmet>
            <div className='main-div'>
                <div className="status-container">
                    <div className="status-box">
                        {
                            status ? (
                                <>
                                    <AiOutlineClose className='close' onClick={() => {
                                        navigate('/');
                                    }} />
                                    <FcOk className='icon-ok' />
                                    <h4 className="heading">Payment Success</h4>
                                    <p className="para">Welcome to Roadserve family.</p>
                                    <p className="para">Please Download Our app from Google Play Store by Clicking <a target='blank' href='https://play.google.com/store/apps/details?id=com.roadserve.roadservepanel'>Here</a> & Register to Activate Your Card</p>
                                </>
                            ) : (
                                <>
                                    <IoMdCloseCircle className='icon-cancel' />
                                    <h4 className="heading">Payment Failed</h4>
                                    <p className="para">Sorry! Try Again.</p>
                                    <p className="para">Please Clicking <span onClick={() => {
                                        window.history.back();
                                    }}>Here</span> to Back</p>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </>

    )
}

export default StatusPage;