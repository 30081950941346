import React, { useState, useEffect } from 'react';
import './Vouchar.scss';
// import { useNavigate} from 'react-router-dom';
// import axios from 'axios';

function Vouchar() {
    // const navigate = useNavigate()
    // const [gst, setGst] = useState();
    const [amount, setAmount] = useState();
    const [prepaid, setPrepaid] = useState(0);
    const [coupon, setCoupon] = useState(0);
    const [total, setTotal] = useState(0);

    // const testUrl = "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay";
    // const prod = "https://api.phonepe.com/apis/hermes/pg/v1/pay";

    const [url, setUrl] = useState();

    const coustomerData = JSON.parse(localStorage.getItem('coustomer_data'));
    const paymentData = JSON.parse(localStorage.getItem('payment_details'));

    useEffect(() => {
        let total = coustomerData.amount;
        // console.log("data: ", paymentData)
        // checksum, encoded_payload, merchantTransactionId

        if (coustomerData) {
            setAmount(Number(coustomerData.amount));
            if (coustomerData.plan === "ultimate") {
                if (coustomerData.method === "prepaid") {
                    setPrepaid(300);
                    total = total - 300;
                } else {
                    setPrepaid(0);
                }
            }
        }
        if (paymentData) {
            if (paymentData.coupon_discount) {
                setCoupon(paymentData.coupon_discount);
                total = total - paymentData.coupon_discount;
            } else {
                setCoupon(0)
            }
        } else {
            setUrl(null);
        }
        setTotal(total);
    }, [])

    // const proceed_handler = () => {
    //     const options = {
    //         method: 'POST',
    //         url: testUrl,
    //         headers: {
    //             accept: 'application/json',
    //             'Content-Type': 'application/json',
    //             'X-VERIFY': paymentData.checksum,
    //         },
    //         data: { request: paymentData.encoded_payload }
    //     };
    //     axios
    //         .request(options)
    //         .then((response) => {
    //             console.log(response.data);
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // }

    return (
        <div className="main-div">
            <div className='vouchar-container'>
                <div className="voucher-box">

                    <div className="form-header">
                        <h3 className="heading">Invoice</h3>
                    </div>

                    <div className="table">

                        <div className="table-row">
                            <p className="items">Membership</p>
                            <p className="items">{amount}</p>
                        </div>

                        <div className="table-row">
                            <p className="items">Coupon Discount</p>
                            <p className="items">{coupon}</p>
                        </div>

                        <div className="table-row">
                            <p className="items">Prepaid Discount</p>
                            <p className="items">{prepaid}</p>
                        </div>

                        <div className="border_blank"></div>

                        <div className="table-row">
                            <p className="total-items">Total Bill</p>
                            <p className="total-items">{total}</p>
                        </div>

                    </div>

                    <a href={url} className='button'>Proceed To Pay</a>
                </div>
            </div>
        </div>
    )
}

export default Vouchar;