
import appimg from '../Images/ROADSERVE JUMPSTART PAGE/Road Serve App.jpg';

export const questions = [
    {
        que : "We Repair Your Car With Safely And Effectively?",
        ans : 'Our trained professionals will quickly and safely jump start your cars battery so you can get back on the road with complete faith in your vehicles performance.',
        img : 'https://i0.wp.com/michiganmamanews.com/wp-content/uploads/2019/07/car-repair.jpg?resize=640%2C426&ssl=1',
    },
    {
        que : "What To Do While Jump Starting Your Car?",
        ans : 'During a car battery jump start service: 1. Ensure safety. 2. Turn off both cars. 3. Properly connect jumper cables. 4. Start donor car. 5. Start dead car. 6. Disconnect cables cautiously.',
        img : 'https://www.honkforhelp.com/wp-content/uploads/2015/10/iStock_000017624465XXXWebRes.jpg',
    },
    {
        que : "What Not To Do While Jump Starting Your Car?",
        ans : 'When using a 24/7 hours jump start service, avoid: 1. Crossing jumper cables. 2. Allowing cable ends to touch. 3. Jump-starting in a closed space. 4. Jump-starting a damaged battery.',
        img : 'https://res.cloudinary.com/yourmechanic/image/upload/dpr_auto,f_auto,q_auto/v1/article_images/jumpstart',
    },
    {
        que : "Do those portable jump starter battery packs really work?",
        ans : 'Battery Jump Start Service may benefit from portable jump starter battery packs. They provide a steady flow of energy to jump-start automobiles independently of another vehicle.',
        img : 'https://static1.howtogeekimages.com/wordpress/wp-content/uploads/2022/09/240e10d0.jpg',
    },
    {
        que : "Should you always keep jumper cables in your car?",
        ans : 'Even if you plan to use our Battery Jump Start Service whenever you need it (which is always), you should still carry jumper cables just in case. When you need a jump start in a need, they may be a real lifesaver.',
        img : 'https://carfromjapan.com/wp-content/uploads/2016/12/jumper-cables-use.jpg',
    },
    {
        que : "What’s the best car battery jump starter?",
        ans : 'The best car battery jump starter varies based on individual needs, but popular options include NOCO Boost Plus, DBPOWER 800A, and GOOLOO 800A. Choose one suitable for your 24/7 Battery Jump Start Service needs.',
        img : 'https://hips.hearstapps.com/hmg-prod/images/sim4638-1674142531.jpg?crop=1xw:1xh;center,top&resize=980:*',
    },
    {
        que : "How Road Serve App Helpful for Jump Start Service?",
        ans : 'One of the best car battery jump start services is available via the Road Serve App. Its dependable in times of crisis because to its rapid response, tracking capabilities, and round-the-clock customer service.',
        img : appimg,
    },

]
