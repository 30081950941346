import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { GetDetails_URL, Category_URL, EditBlog_URL, AddImg_URL } from "../../../API/API";
import EditBoxs from "../../Container2/EditBoxs";
import { useForm } from "react-hook-form";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Loder from "../../../Loder/Loder";
import { useParams } from "react-router-dom";

function createNotification(type) {
    switch (type) {
        case 'info':
            NotificationManager.info('Info message');
            break;
        case 'success':
            NotificationManager.success('Success message', 'Update Successfully', 3000);
            break;
        case 'warning':
            NotificationManager.warning('Warning message', 'Enter Valid Data', 3000);
            break;
        case 'error':
            NotificationManager.error('Error message', 'Sorry! Try Again', 3000);
            break;
    }
};




function EditBox(props) {
    const [item, setItem] = useState(false);
    const [category, setCategory] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const {is} = useParams();
    const PostId = is.split("=")[1];
    // for input
    const [header, setHeader] = useState();
    const [tags, setTags] = useState('');
    const [content, setContant] = useState();
    const [images, setImages] = useState(false);
    const [alttag, setAlttag] = useState();
    const [head_tag, setHead_tag] = useState();
    const [catId, setCatId] = useState();
    const [catName, setCatName] = useState()
    const { register, handleSubmit } = useForm();



    function callApis() {
        axios.get(`${GetDetails_URL}${PostId}`)
            .then((res) => {
                const data = res.data.data[0];
                setHeader(data.heading);
                setContant(data.content);
                const tags = () => {
                    let tagsData = '';
                    for (var i = 0; i < data.tags.length; i++) {
                        tagsData = tagsData + data.tags[i] + ",";
                    }
                    return tagsData;
                }
                setTags(tags())
                setCatId(data.category.id[0])
                setCatName(data.category.name[0])
                setAlttag(data.alt_tag)
                setHead_tag(data.head_tag);
                setItem(data);
                // console.log("cate :", data.category.id[0])
            })
            .catch((err) => {
                console.log("Details err : ", err)
            })

        axios.get(Category_URL)
            .then((res) => {
                setCategory(res.data);
                // console.log(res.data)
            })
            .catch((err) => {
                console.log("Category err : ", err)
            })
    }

    useEffect(() => {
        callApis()
    }, [])

    const onSubmit = (data) => {
        // console.log(data);
        setSpinner(true);
        const token = localStorage.getItem("admin_token")
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        const tag = data.tag.split(",");
        const ItemData = {
            heading: data.heading,
            content: content,
            category: data.category,
            tags: tag,
            meta_tags: item.meta_tags,
            alt_tag: data.alttag,
            head_tag: data.head_tag,
        }

        axios.put(`${EditBlog_URL}${PostId}`, ItemData, config)
            .then(res => {
                if (images) {
                    const formData = new FormData();
                    formData.append('images', data.images[0], data.images[0].name);
                    axios.post(`${AddImg_URL}${PostId}`, formData, config)
                        .then((res) => {
                            // console.log("add img res :", res);
                            createNotification('success')
                            props.getPost('blog')
                        })
                        .catch((err) => {
                            console.log("add img err :", err);
                            createNotification('error');
                        })
                } else {
                    createNotification('success');
                    props.getPost('blog')
                }
                setSpinner(false);
            })
            .catch(err => {
                createNotification('error');
                console.log("update err :", err);
                setSpinner(false);
            })
    }

    const contant_handler = (value) => {
        setContant(value);
    }

    return (
        <div className='add-post-container'>
            <h3>Edit Blog</h3>

            {
                item ? (
                    <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                        <Form.Label>The images you add should not exceed the size of 1mb</Form.Label>
                        <Form.Control size="lg" placeholder="Blog Heading" type="text" name="heading" {...register("heading")} value={header} onChange={(e) => setHeader(e.target.value)} required />

                        <EditBoxs getContant={contant_handler} postcontent={content} />

                        <Form.Control name="tag" placeholder="Enter Blog Tag1, Tag2, Ect" {...register("tag")} value={tags} onChange={(e) => setTags(e.target.value)} required />

                        <div className="select-box">
                            <Form.Select size="lg" {...register("category")} name="category" required>
                                <option value={catId}>{catName}</option>
                                {
                                    category.length > 0 ? (
                                        category.map((item, index) => (
                                            <option value={item.category_id} key={index}>{item.name}</option>
                                        ))
                                    ) : null
                                }
                            </Form.Select>

                            <Form.Control type="file" name="images" {...register("images")} onChange={(e) => setImages(e.target.value)} />
                        </div>


                        <div className="select-box">
                            <Form.Control name="alttag" {...register("alttag")} placeholder="Enter Image Alt Tag" value={alttag} onChange={(e) => setAlttag(e.target.value)} required style={{ width: "50%" }} />
                            <Form.Control name="head_tag" {...register("head_tag")} as="textarea" placeholder="Enter Your Head Tags" value={head_tag} onChange={(e) => setHead_tag(e.target.value)} required />
                        </div>




                        {
                            spinner ? (
                                <Button variant="warning">Post Updating...</Button>
                            ) : (
                                <Button variant="primary" type="submit">Post Update</Button>
                            )
                        }

                    </form>
                ) : (<Loder />)
            }

            <NotificationContainer />
        </div>
    )
}

export default EditBox