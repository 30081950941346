import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import './Container.scss';
import img1 from '../../../../Images/ROADSERVE JUMPSTART PAGE/web-header.jpg';
import img2 from '../../../../Images/ROADSERVE JUMPSTART PAGE/Flat Tyre.jpg';
import img3 from '../../../../Images/ROADSERVE JUMPSTART PAGE/Towing service.jpg';


function CarouselContainer() {
    const [screens, setscreens] = useState([img1, img2, img3])
  
  return (
    <div className="CarouselContainer">
    <Carousel className="slider">
      {screens.map((item, index) => (
        <Carousel.Item interval={2000} className="boxes">
          <img src={item} alt="img" className="screen" key={index}/>
        </Carousel.Item>
      ))}
    </Carousel>
  </div>
  )
}

export default CarouselContainer