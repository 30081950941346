import React from 'react';
import DeleteTags from './SubContainer/DeleteTags';
import AddCategory from './SubContainer/AddCategory';
import DeleteCategory from './SubContainer/DeleteCategory';
function CattegoryTags() {
  return (
    <div className='add-post-container'>

      <DeleteTags />
      <AddCategory/>
      <DeleteCategory/>

    </div>
  )
}

export default CattegoryTags