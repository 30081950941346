import React, { useContext, useEffect } from "react";
import { Storage } from "../../App";
import { useNavigate } from "react-router-dom";
import Jumpstart from "../PagesComponents/Jumpstart/Jumpstart";

function JumpStartPage() {
    const navigate = useNavigate();
    const { postUserlog, getPage } = useContext(Storage);

    useEffect(() => {
        if (postUserlog) {
          navigate("/user/home");
        }
        getPage("Jumpstart");
      }, []);

  return (
    <Jumpstart/>
  )
}

export default JumpStartPage