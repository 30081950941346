import React from 'react';
import easy from "../../../../Images/Icons/jump-start.png";
import like from "../../../../Images/Icons/start.png";
import safe from "../../../../Images/Icons/battery.png";

function About() {
    return (
        <div className="about-components sec-con">
            <div className="about-container sec-con-inner">
                <div className="container">
                    <p className="header">Jump Start Service</p>
                    <h3 className="head-tag">What Is Jump Start Service</h3>
                    <p className="pera">
                        A jump start is a kind of emergency roadside assistance for automobiles whose batteries have died. Jumper cables and a power source, usually another vehicle or a portable jump starter, are among the tools a skilled expert would bring to the scene when a car's battery is dead and won't turn over the engine. The technician hooks up these gadgets to the car's dead battery and transfers electrical power to the engine. This procedure gives the car a short-term boost that allows it to get moving and keep going. When a car's battery dies and the driver is stopped, a jump start service might save them time and trouble.
                    </p>
                </div>
                <div className="logos">
                    <div className="items">
                        <img src={easy} alt="Car Mechanic Near Me Home Service In Kolkata" className="icons" />
                        <p>Easy on Pocket.</p>
                    </div>
                    <div className="blank"></div>
                    <div className="items">
                        <img src={safe} alt="Car Road Service Near Me Ahmedabad" className="icons" />
                        <p>Good to have.</p>
                    </div>
                    <div className="blank"></div>
                    <div className="items">
                        <img src={like} alt="Hyundai Road Side Assistance In Amritsar" className="icons" />
                        <p>Safe. Relaxing.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About