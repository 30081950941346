import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import "./Jumpstart.scss";
import About from "./Container/About";
import Footer from "../../Components/Footer/Footer";
import CarouselContainer from "./Container/CarouselContainer";
import Service from "./Container/Service";
import Works from "./Container/Works";
import Testimonial from "../../Components/Testimonial/Testimonial";
import Question from "./Question/Question";

function Jumpstart() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="jumpstart-container">
      <Helmet>
        <title> Car Battery Jump Start Service | Battery Jump Start Service </title>
        <meta name="description" content=" Looking for an emergency Car Battery Jump Start Service in the Mumbai Area Our professional auto mechanics provide immediate jumpstart services to bring your vehicle back to life." />
        <meta name="keywords" content=" Car Battery Jump Start Service, Battery Jump Start Service, 24/7 Battery Jump Start Service, Jump Start Service, Jump Start Car Service Near Me Mumbai, Jump Start Service In Delhi " />
        <meta name="keyphrase" content=" Battery Jump Start Service, 24/7 Battery Jump Start Service " />
        <meta name="keyphrase" description=" Get back on the road quickly after a dead battery with help from the trained professionals at Road Serve, who are experts in providing Battery Jump Start Service. " />
        <meta name="description" content="Ridcor" />
        <meta name="keywords" content="Ridcor" />
        <meta name="revisit-after" content="7 days" />
        <meta name="Author" content="https://roadserve.in" />
        <meta name="Copyright" content="Road Serve" />
        <meta name="rating" content="general" />
        <meta name="distribution" content="global" />
        <meta property="og:title" content="24/7 Battery Jump Start Service, Jump Start Service " />
        <meta property="og:type" content="website" />
        <meta property="og:description" content=" If your DIY restart effort fails, the best answer is to phone a free 24/7 Battery Jump Start Service roadside assistance number, which will provide prompt assistance for your vehicle's battery problems. " />
        <meta property="og:image" content=" https://roadserve.in/static/media/web-header.19cb8b94fdfe454d5fb4.jpg " />
        <meta property="og:url" content="https://roadserve.in" />
        <link rel="canonical" href="https://roadserve.in" />
        <meta property="og:site_name" content="Road Serve" />
        <meta itemprop="name" content=" Jump Start Service, Jump Start Service In Delhi " />
        <meta itemprop="description" content=' Road Serve may provide advice on Jump Start Service for your car or conduct it on site, allowing you to get help at any time and in any place that fits your requirements. ' />
        <meta itemprop="url" content="https://roadserve.in" />
        <meta name="twitter:title" content=" 24/7 Hours Car Battery Jump Start Services In Kanpur " />
        <meta name="twitter:description" content=" Looking for 24/7 Hours Car Battery Jump Start Services In Kanpur, Let our trained technicians breathe new life into your automobile with our fast battery jumpstart service. " />
        <meta name="twitter:image" content=" https://roadserve.in/static/media/Towing%20service.64bb74e3873bac3821d2.jpg " />
        <meta name="twitter:card" content="summary_large_image"></meta>
      </Helmet>
      <>
        <CarouselContainer />
        <About />
        <Service />
        <Question/>
        <Testimonial/>
        <Works />
        <Footer />
      </>
    </div>
  )
}

export default Jumpstart