import React from "react";
import easy from "../../../Images/Photos/easy.png";
import like from "../../../Images/Photos/like.png";
import safe from "../../../Images/Photos/safety.png";
import "./About.scss";

function About() {
  return (
    <div className="about-components">
      <div className="about-container">
        <div className="container">
          <p className="header">About Us</p>
          <h3 className="head-tag">About The ROADSERVE</h3>
          <p className="pera">
            Introducing Roadserve - a premier car assistance company dedicated
            to providing top-notch services to car owners across India. Whether
            it's a flat tire, a dead battery, or a breakdown, Roadserve's team
            of experienced professionals is always ready to provide prompt and
            reliable assistance. With a commitment to customer satisfaction and
            a focus on quality service, Roadserve is the go-to destination for
            all your car assistance needs.
          </p>
        </div>
        <div className="logos">
          <div className="items">
            <img src={easy} alt="Car Mechanic Near Me Home Service In Kolkata" className="icons" />
            <p>Easy on Pocket.</p>
          </div>
          <div className="blank"></div>
          <div className="items">
            <img src={like} alt="Car Road Service Near Me Ahmedabad" className="icons" />
            <p>Good to have.</p>
          </div>
          <div className="blank"></div>
          <div className="items">
            <img src={safe} alt="Hyundai Road Side Assistance In Amritsar" className="icons" />
            <p>Safe. Relaxing.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
