import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Blog_URL, DeleteBlog_URL } from '../../API/API';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useNavigate } from "react-router-dom";
import EditBox from '../Container/SubContainer/EditBox';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Loder from '../../Loder/Loder';
import Pagination from '../../UserPanel/PagesComponents/Blog/Container/PaginationComponents';
function createNotification(type) {

    switch (type) {
        case 'info':
            NotificationManager.info('Info message');
            break;
        case 'success':
            NotificationManager.success('Success message', 'Successfull', 3000);
            break;
        case 'warning':
            NotificationManager.warning('Warning message', 'Enter Valid Data', 3000);
            break;
        case 'error':
            NotificationManager.error('Error message', 'Sorry! Try Again', 3000);
            break;
    }
};


function AllIBlog() {
    const navigate = useNavigate();
    const [blogId, setBlogId] = useState(false);
    const [blogItems, setBlogItems] = useState();
    const [reload, setrelode] = useState(false);
    const [pageIs, setPageIs] = useState("blog");
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);

    function callApi() {
        axios.get(`${Blog_URL}page=${page}&limit=12`)
            .then((res) => {
                setBlogItems(res.data.data);
                setTotalPage(res.data.total_pages);
            })
            .catch((err) => {
                console.log("err : ", err)
            })
    }


    const page_handler = (value) => {
        setPage(value);
    };

    useEffect(() => {
        callApi()
    }, [])

    useEffect(() => {
        callApi()
    }, [page])

    useEffect(() => {
        callApi()
    }, [reload])

    const Edit_handler = (id) => {
        setPageIs("edit-blog");
        navigate(`/admin/blog/postId=${id}`);
    }



    const delete_handler = (id) => {
        const token = localStorage.getItem("admin_token");
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }

        axios.delete(`${DeleteBlog_URL}${id}`, config)
            .then(res => {
                setrelode(!reload);
                createNotification('success');
                setBlogId(false);
            })
            .catch(err => {
                console.log("err :", err);
                createNotification('error')
                setBlogId(false);
            })
    }

    const readmore_handler = (id, heading) => {

        // navigate(`/blog/details/search=${title}/id=${id}`)
        const remove_space = heading.split(" ").join('-');
        const remove_question = remove_space.split("?").join('-');
        const remove_slash = remove_question.split("/").join('-');
        const remove_and = remove_slash.split("&").join('-');
        // console.log("URL : ", remove_and);
        navigate(`/blog/details/search=${remove_and}/id=${id}`);
    }



    return (
        <div className='add-post-container'>
            {
                pageIs === 'blog' ? (
                    <div className="all-blog-container">
                        {
                            blogItems ? (
                                blogItems.map((item, index) => (
                                    <Card className='item-box' key={index}>
                                        <Card.Img variant="top" src={`https://admin.roadserve.in/${item.images[0].split("/code")[1]}`} />
                                        <Card.Body className='body'>
                                            <Card.Title>{item.heading}</Card.Title>

                                            <Button className='more-btn' variant="primary" onClick={() => readmore_handler(item._id.$oid, item.heading)}>Read More</Button>

                                            <div className="button-div">
                                                <Button className='btn' variant="primary" onClick={() => Edit_handler(item._id.$oid)}>Edit</Button>
                                                <Button className='btn' variant="primary" onClick={() => delete_handler(item._id.$oid)}>{blogId === item._id.$oid ? "Deleting..." : 'Delete'}</Button>
                                            </div>
                                        </Card.Body>

                                    </Card>
                                ))
                            ) : (
                                <Loder />
                            )
                        }

                    </div>
                ) : (
                    <EditBox getPost={setPageIs} />
                )
            }

            {
                blogItems ? (
                    <div className="pegination-box">
                        <Pagination
                            getPage={page_handler}
                            postPage={page}
                            totalPage={totalPage}
                        />
                    </div>

                ) : null
            }
            <NotificationContainer />
        </div>
    )
}

export default AllIBlog