import React, { useEffect, useState } from 'react';
import './VerifyNumber.scss';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Form from 'react-bootstrap/Form';
import { useParams, useNavigate } from 'react-router-dom';
import { verifyNumber_URL } from '../../../API/API';
import axios from 'axios';

function createNotification(type) {
    switch (type) {
        case 'info':
            NotificationManager.info('Info message');
            break;
        case 'success':
            NotificationManager.success('Success message', 'Send OTP Successfully', 3000);
            break;
        case 'warning':
            NotificationManager.warning('Warning message', 'Enter Valid Number', 3000);
            break;
        case 'error':
            NotificationManager.error('Error message', 'Sorry! Try Again', 3000);
            break;
    }
};

const schema = yup.object({
    contact_number: yup.string().required(),
    whatsapp: yup.mixed().required(),
}).required();


function VerifyNumber() {
    const navigate = useNavigate();
    const param = useParams();
    const [plan, setPlan] = useState();
    const [spinner, setSpinner] = useState(false);
    const method = param.method.split('=')[1];

    useEffect(() => {

        if (param.plan) {
            const planVal = param.plan.split('=')[1];
            if (planVal === 'light' || planVal === 'basic' || planVal === 'advance' || planVal === 'ultimate') {
                setPlan(planVal);
            } else {
                navigate('/plans')
            }
        }
    }, [])

    const { register, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data) => {
        setSpinner(true);
        if (data.contact_number.length === 10) {
            const num = data.contact_number;
            axios.post(verifyNumber_URL, data)
                .then((res) => {
                    setSpinner(false)
                    navigate(`/plans/plan=${plan}/method=${method}/number=${num}/whatsapp=${true}`)
                })
                .catch((err) => {
                    setSpinner(false)
                    console.log('send otp error : ', err)
                })
        } else {
            setSpinner(false)
            createNotification("warning")
        }
    };


    return (
        <div className="main-div">
            <div className='verify-container'>
                <form onSubmit={handleSubmit(onSubmit)} className='verify-form'>
                    <div className="form-header">
                        <h3 className='heading'>Verify Number</h3>
                        <p className="form-para">You will receive a 4 digit code to verify next</p>
                    </div>
                    <input {...register("contact_number")} type='number' className='input-field' name='contact_number' placeholder='Enter Mobile Number' required />
                    <Form.Check {...register("whatsapp")} name='whatsapp' type="checkbox" label="Is this your whatsapp number ?" className='check-box' checked />
                    {
                        spinner ? <button className='btn'>Sending...</button> : <button type="submit" className='btn'>Send OTP</button>
                    }

                </form>
                <NotificationContainer />
            </div>
        </div>

    )
}

export default VerifyNumber