import React, { useState, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import "react-notifications/lib/notifications.css";
import './VerifyOTP.scss';
import axios from "axios";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useNavigate, useParams } from "react-router-dom";
import { verifyOTP_URL, verifyNumber_URL } from "../../../API/API";

function createNotification(type) {
    switch (type) {
        case 'info':
            NotificationManager.info('Info message');
            break;
        case 'success':
            NotificationManager.success('Success message', 'Send OTP Successfully', 3000);
            break;
        case 'warning':
            NotificationManager.warning('Warning message', 'Enter Valid OTP', 3000);
            break;
        case 'error':
            NotificationManager.error('Error message', 'Sorry! Try Again', 3000);
            break;
    }
};

function VerifyOtp() {
    const [seconds, setSeconds] = useState(30);
    const [otpfield, setOtpfield] = useState(new Array(4).fill(""));
    const [number, setNumber] = useState();
    const [plan, setPlan] = useState();
    const [amount, setAmount] = useState(0);
    const [whatsapp, setWhatsapp] = useState();
    const [spinner, setSpinner] = useState(false);
    const navigate = useNavigate();
    const param = useParams();
    const method = param.method.split('=')[1];



    useEffect(() => {
        if (param.number) {
            const num = param.number.split('=')[1];
            setNumber(num)
        }
        if (param.plan) {
            const plan = param.plan.split('=')[1];
            setPlan(plan)
            if (plan === 'light') {
                setAmount('999')
            } else if (plan === 'basic') {
                setAmount('1999')
            } else if (plan === 'advance') {
                setAmount('3999')
            } else if (plan === 'ultimate') {
                setAmount('4999')
            }
        }
        if (param.whatsapp) {
            const wp = param.whatsapp.split('=')[1];
            setWhatsapp(wp)
        }

    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const handelchange = (element, index) => {
        if (isNaN(element.value)) return false;
        setOtpfield([
            ...otpfield.map((d, idx) => (idx === index ? element.value : d)),
        ]);
        // focus next input
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const back_btn = () => {
        window.history.back();
    };

    const onSubmit = () => {
        const otpval = otpfield[0] + otpfield[1] + otpfield[2] + otpfield[3];
        setSpinner(true)
        const payload = {
            plan: plan,
            amount: amount,
            method: 'prepaid',
        }
        if (otpval.length === 4) {
            axios.get(`${verifyOTP_URL}${number}&otp=${otpval}`)
                .then((res) => {
                    const token = res.data.token;
                    localStorage.setItem("token_plans_log", token);
                    let det;
                    if (res.data.data === null) {
                        det = false;
                        const Data = {
                          name:'',
                          car_number: '',
                          car_model: '',
                          contact_number: '',
                          address: '',
                          pin_code: '',
                          state: '',
                          country : '',
                          email : '',
                        };
                        localStorage.setItem("coustomer_data", JSON.stringify(Data));
                    } else {
                        const Data = res.data.data[0];
                        det = true;
                        localStorage.setItem("coustomer_data", JSON.stringify(Data));
                    }
                    localStorage.setItem('Payment_data', JSON.stringify(payload));
                    setSpinner(false);

                    if(method === 'cod'){
                        navigate(`/verify/details=${det}/plan=${plan}/number=${number}`);
                    }else {
                        document.getElementById('hrefTag').click();
                    }
                    
                    
                })
                .catch((err) => {
                    setSpinner(false)
                    if(err.status === 403){
                        alert(err.response.data.detail)
                    }
                    console.log("verify otp error : ", err)
                })
        } else {
            createNotification("warning")
        }
    };

    const resendOTP = () => {
        const data = {
            contact_number: number,
            whatsapp: whatsapp,
        }
        axios.post(verifyNumber_URL, data)
            .then((res) => {
                setSeconds(30);
            })
            .catch((err) => {
                console.log("Resend OTP Error: ", err)
            });
    };

    return (
        <div className="main-div">
            <div className='verify-container'>

                <div className="otp-container">
                    <BiArrowBack className="otp-back-btn" onClick={back_btn} />
                    <div className="form-header">
                        <p className="heading">Verify OTP</p>
                        <h3 className="form-para">We sent you an SMS code</h3>
                        <p className="form-para">On number: +91 {number}</p>
                    </div>

                    <form className="otp-form">
                        <div className="otp-field">
                            {otpfield.map((data, index) => {
                                return (
                                    <input
                                        type="number"
                                        className="otpfield"
                                        placeholder="0"
                                        maxlength="1"
                                        key={index}
                                        value={data}
                                        onChange={(e) => handelchange(e.target, index)}
                                        onFocus={(e) => e.target.select()}
                                    />
                                );
                            })}
                        </div>
                    </form>

                    {
                        spinner ? <button className="btn">OTP Verifying...</button> : <button className="btn" onClick={onSubmit}>Verify OTP</button>
                    }



                    <div className="footer-container">
                        {seconds > 0 ? (
                            <p className="remaining">
                                Time Remaining:{" "}
                                {seconds < 10 ? `0${seconds} sec` : `${seconds} sec`}
                            </p>
                        ) : (
                            <p className="remaining">
                                Didn't recieve code?
                                <div
                                    disabled={seconds > 0}
                                    onClick={resendOTP}
                                    style={{ color: seconds > 0 ? "#DFE3E8" : "#007aff" }}
                                    className="otp-btn"
                                >
                                    Resend
                                </div>
                            </p>
                        )}
                    </div>
                </div>
                <a href={`https://roadserve.in/payment/${plan}`} style={{ display: 'none' }} id="hrefTag">ok</a>
                <NotificationContainer />
            </div>
        </div>
    )
}

export default VerifyOtp