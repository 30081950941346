import React, { useEffect, useState } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Blog_URL } from "../../../../API/API";
import axios from "axios";


const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 4 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 767, min: 464 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    }
};

const Slider = () => {
    const [spinner, setSpinner] = useState(true);
    const [page, setPage] = useState(1);
    const [Items, setItems] = useState(false);

    useEffect(() => {
        axios.get(`${Blog_URL}page=${page}&limit=12`)
            .then((res) => {
                setSpinner(false);
                if (res.data.data) {
                    setItems(res.data.data);
                }
                console.log("Slider Blog Error :", res);
            })
            .catch((err) => {
                console.log("Slider Blog Error :", err);
            });
    },[])

    return (
        <div className="parent">
            {
                !spinner ? (
                    <Carousel
                        responsive={responsive}
                        autoPlay={true}
                        swipeable={true}
                        draggable={true}
                        showDots={true}
                        infinite={true}
                        partialVisible={false}
                        dotListClass="custom-dot-list-style"
                    >
                        {Items.map((item, index) => (
                            <div className="slider" key={index}>
                                <div className="image-container">
                                    <img src={`https://admin.roadserve.in/${item?.images[0]?.split("/code")[1]}`} alt={item.alt_tag} className='image' />
                                </div>
                                <h4 className="title">{item.heading}</h4>
                                <div className="buttom-text-box">
                                    <a target="_blank" href={`https://roadserve.in/blog/details/search=${item.heading}/id=${item._id.$oid}`} className='button readmore'>Read More</a>
                                </div>
                            </div>
                        )
                        )}
                    </Carousel>
                ) : null
            }

        </div>
    );
};
export default Slider;
