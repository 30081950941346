import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {ItemsForMobile, ItemsForWeb} from '../../../Datas/NavbarData';
import '../../Scss/Navbar.scss';
import image from '../../../Images/NavbarLogo.png';
import Button from 'react-bootstrap/Button';
import {useState } from 'react';
import ContactPopup from '../Popup/ContactPopup';
import { Link } from 'react-router-dom';


function UserNavbar() {
    const [contactStatus, setContactStatus] = useState("focus-hide");

    const contact_btn_handler = () => {
        setContactStatus("focus-visible");
    }
    
    const setStatus_handler = (value) => {
        setContactStatus(value);
    }
    
    const toggle_handler = () => {
        const toggle = document.getElementById("toggle-tab")
        toggle.click();
    }
    
    return (
        <Navbar expand="lg" className="user-navbar">
            <ContactPopup sendStatus={contactStatus} recStatus={setStatus_handler}/>
            <div className="nav-container-for-web for-web">
                <a href="/" className="brand">
                    <img src={image} alt="Logo" className="brand-logo" />
                </a>
                <div className="menus">
                    {
                        ItemsForWeb.map(items => (
                            <Link to={items.link} key={items.id} className="links">{items.name}</Link>
                        ))
                    }
                </div>
                <div className="btns">
                    <Button className="contact-btn" onClick={contact_btn_handler}>Contact Us</Button>
                </div>
            </div>

            <Container fluid className="nav-container-for-mobile for-mobile">
                <Navbar.Brand href="/" className="brand">
                    <img src={image} alt="Logo" className="brand-logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll"  id='toggle-tab'/>
                <Navbar.Collapse id="navbarScroll" className="content2">
                    <Nav className="me-auto my-2 my-lg-0 2 menus-item" navbarScroll>
                        {
                            ItemsForMobile.map(items => (
                                <Link to={items.link} key={items.id} className="links" onClick={toggle_handler}>{items.name}</Link>
                            ))
                        }
                    </Nav>
                    <div className="btns">
                        <Button className="contact-btn" onClick={contact_btn_handler}>Contact Us</Button>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
export default UserNavbar;