import React, { useContext, useEffect } from "react";
import { Storage } from "../../App";
import { useNavigate } from "react-router-dom";
import Blog from '../PagesComponents/Blog/Blog';
function BlogPage() {
  const navigate = useNavigate();
  const { postUserlog, getPage } = useContext(Storage);

  useEffect(() => {
    if (postUserlog) {
      navigate("/user/home");
    }
    getPage("Blog");
  }, []);
  return <Blog />;
}

export default BlogPage;
