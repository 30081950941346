import React, { useContext, useEffect } from "react";
import { Storage } from "../../App";
import { useNavigate } from "react-router-dom";
import BlogDetails from '../PagesComponents/BlogDetails/BlogDetails';

function BlogDetailsPage() {
  const navigate = useNavigate();
  const { postUserlog, getPage } = useContext(Storage);
  useEffect(() => {
    if (postUserlog) {
      navigate("/user/home");
    }
    getPage("Blog-Details");
  }, []);
  return (
    <BlogDetails/>
  )
}

export default BlogDetailsPage;