import React, { useEffect, useState } from "react";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import backgroundimg from "../../../Images/Background/hero.png";
import "./Herosection.scss";
import img1 from "../../../Images/HeroImg/img1.png";
import img2 from "../../../Images/HeroImg/img2.png";
import img3 from "../../../Images/HeroImg/img3.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "animate.css";

const items = [
  {
    id: 1,
    head: "Facing a hustle on the road with your car?",
    para: "Roadserve is a leading company providing reliable car assistance services throughout India. With a team of highly skilled professionals and a 24/7 helpline.",
    link: "https://play.google.com/store/apps/details?id=com.roadserve.roadservepanel",
    img: img1,
  },
  {
    id: 2,
    head: "PLANNING A LONG ROADTRIP WITH FRIENDS OR FAMILY?",
    para: "With Roadserve, you’re never required to worry about car breakdown related problems. Our mechanics are always there for you in case of a breakdown or an emergency",
    link: "https://play.google.com/store/apps/details?id=com.roadserve.roadservepanel",
    img: img2,
  },
  {
    id: 3,
    head: "Roadserve is the way",
    para: "Roadserve gives you the confidence to ride to anywhere in PAN India without ever being worried about a flat tyre or battery issue or any kind of breakdowns that may happen in a roadtrip",
    link: "https://play.google.com/store/apps/details?id=com.roadserve.roadservepanel",
    img: img3,
  },
];
function Home() {
  const [indexs, setIndexes] = useState(0);
  const [clsName, setClsName] = useState("box-0");

  useEffect(() => {
    let i = 0;
    setInterval(() => {
      if (i < items.length - 1) {
        setIndexes(i + 1);
        setClsName(`box-${i}`);
        i++;
      } else {
        setIndexes(0);
        setClsName(`box-0`);
        i = 0;
      }
    }, 6000);
  }, []);

  const left_click = () => {
    if (indexs === 0) {
      setIndexes(items.length - 1);
    } else {
      setIndexes(indexs - 1);
    }
  };

  const right_click = () => {
    if (indexs === items.length - 1) {
      setIndexes(0);
    } else {
      setIndexes(indexs + 1);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="home-components" id="home">
      
      <div className="home-container">
        <img src={backgroundimg} alt="24 7 Roadside Assistance In Mumbai" />

        <div className={`container-boxes ${clsName}`}>
          <div className="left-container">
            <h3 className="header" id="heading">{items[indexs].head}</h3>
            <p className="pera">{items[indexs].para}</p>
            <div className="btn-container">
              <a
                href={items[indexs].link}
                target="blank"
                className="button focus"
              >
                <IoLogoGooglePlaystore className="icons" />
                Download for Android
              </a>
            </div>
          </div>

          <div className="right-container">
            <div className="imgs">
              <img src={items[indexs].img} alt="My Car Roadside Assistance In Amritsar" className="item-img" />
            </div>

            <div className="button-container">
              <ArrowBackIcon className="icons" onClick={left_click} />
              <ArrowForwardIcon className="icons" onClick={right_click} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
