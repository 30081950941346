import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function EditBox(props) {
    const [value, setValue] = useState('');
    const module = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'align': [] }],
            ['link', 'image'],
            [{ 'color': [] }],
            ['clean']
        ],
    }
    useEffect(() => {
        if(value !== "<p><br></p>"){
           props.getContant(value); 
        } else {
            props.getContant(false);
        }
    },[value])

    return (
        <div className='editor-container'>
            <ReactQuill
                theme="snow"
                onChange={setValue}
                value={value}
                modules={module}
                bounds={'.app'}
                placeholder="add content for blog"
            />
        </div>
    )
}

export default EditBox