import React, { useContext, useEffect } from "react";
import "./Service.scss";
import { useNavigate } from "react-router-dom";
import headimg from "../../../Images/Background/service-head.png";
import { AiOutlineHome } from "react-icons/ai";
import Footer from "../../Components/Footer/Footer";
import { BsGooglePlay } from "react-icons/bs";
import Assisstance from "../../../Images/Icons/settings.svg";
import Repairing from "../../../Images/Icons/repiering.svg";
import Services from "../../../Images/Icons/car1.svg";
import Weak from "../../../Images/Icons/bettary.svg";
import Punctured from "../../../Images/Icons/punchartd.svg";
import Discounted from "../../../Images/Icons/Discounted Parts.svg";
import CarorSuv from "../../../Images/Icons/Car or Suv.svg";
import Dedicated from "../../../Images/Icons/Dedicated Support.svg";
import ServiceContainer from "./Container/ServiceContainer";
import Servicecar from "./Container/Servicecar";
import appsimg from "../../../Images/Background/apps-service-ss.png"; //
import img1 from "../../../Images/Icons/image1.png";
import img2 from "../../../Images/Icons/image2.png";
import img3 from "../../../Images/Icons/image3.png";
import img4 from "../../../Images/Icons/image4.png";

import { Storage } from "../../../App";

function Service() {
  const navigate = useNavigate();
  const { getServiceId, postServiceId } = useContext(Storage);
  const boxitem = [
    {
      id: 1,
      icon: Assisstance,
      head: "Roadside Assistance",
      pera: "Roadserve offers comprehensive 24x7 roadside assistance to help you overcome unexpected car troubles. Whether you have a flat tire, a drained battery, or any other issue, our experienced professionals are just a call away. We provide prompt on-site assistance, ensuring your safety and getting you back on the road as quickly as possible.",
      class: "icon1",
    },
    {
      id: 2,
      icon: Repairing,
      head: "Fuel Delivery",
      pera: "Running out of fuel is an inconvenience that can leave you stranded. Roadserve offers fuel delivery services to ensure you never get stuck due to low fuel. Simply contact us, and our team will deliver the fuel you need to get you back on track.",
      class: "icon2",
    },
    {
      id: 3,
      icon: Services,
      head: "Towing Services",
      pera: "In the event of a major breakdown or accident, Roadserve offers reliable towing services. Our professional towing partners will transport your vehicle to the nearest authorized service center or your preferred location, ensuring it receives the necessary repairs.",
      class: "icon3",
    },
    {
      id: 4,
      icon: Weak,
      head: "Battery Jumpstart",
      pera: "If your car's battery dies and you're unable to start your vehicle, Roadserve offers battery jumpstart services. Our technicians will arrive at your location equipped with the necessary tools and expertise to jumpstart your battery and get you moving again.",
      class: "icon4",
    },
    {
      id: 5,
      icon: Punctured,
      head: "Flat Tire Assistance",
      pera: "Dealing with a flat tire can be frustrating, especially when you're on the go. Roadserve provides tire assistance, including tire changing and repair services. Our team will arrive at your location, assess the tire's condition, and either repair it or replace it with a spare tire, ensuring you can continue your journey safely.",
      class: "icon5",
    },
    {
      id: 6,
      icon: Discounted,
      head: "Discounted Parts",
      pera: "As a valued Roadserve customer, you gain access to exclusive discounts on a wide range of car parts and accessories. Enjoy discounted prices while ensuring your vehicle receives high-quality components, saving you money on repairs and upgrades.",
      class: "icon6",
    },
    {
      id: 7,
      icon: CarorSuv,
      head: "Car or Suv",
      pera: "Roadserve makes it easy for you to get the expert auto assistance you need. Whether you drive a car or a SUV, we offer extraordinary services on the road.",
      class: "icon7",
    },
    {
      id: 8,
      icon: Dedicated,
      head: "Dedicated Support",
      pera: "Our team is equipped with expert vehicle technicians and helpful customer service agents, who are highly trained in their profession and are also equipped with world class tools.",
      class: "icon8",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="service-pages-components">
      <div className="header-container">
        <img src={headimg} alt="Near By Car Mechanic In Bhopal" className="backhead" />
        <div className="service-heading-text">
          <h3 className="head-text">What we do?</h3>
          <div className="page-name-links" id="serviceIs">
            <AiOutlineHome className="icon" />
            <p onClick={() => navigate("/")}>Home / </p>
            <p>Services</p>
          </div>
        </div>
      </div>

      <div className="our-service-container">
        <div className="our-service-box">
          <div className="left-container">
            <h3 className="header">Our Services</h3>
            <div className="service-scrolling-box">
              {boxitem.map((item) => (
                <div
                  className={`items-box ${
                    postServiceId === item.id - 1 ? "focus" : null
                  }`}
                  key={item.id}
                  onClick={() => {
                    getServiceId(item.id - 1);
                  }}
                >
                  <div className={`icon-div ${item.class}`}>
                    <img src={item.icon} alt="Car Mechanic Near Me Home Service In Kolkata" className="icons" />
                  </div>
                  <h4 className="heads">{item.head}</h4>
                </div>
              ))}
            </div>
          </div>

          <div className="right-container">
            <ServiceContainer id={postServiceId} />
            <Servicecar />

            <div className="whenever">
              <h3 className="heads">Whenever & Wherever</h3>
              <p className="paras">
                Just call our 24x7 helpline number and relax till the help
                arrives.
              </p>

              <div className="items-boxs">
                <div className="item">
                  <div className="left">
                    <img src={img1} alt="Car Recovery Service Near Me Kolkata" className="iconss" />
                  </div>
                  <div className="right">
                    <h4 className="item-name">
                      24/7 Onsite Support and Assistance
                    </h4>
                    <p className="paras">
                      Simply call our helpline or use our app to get assistance
                      on-road 24/7
                    </p>
                  </div>
                </div>

                <div className="item">
                  <div className="left">
                    <img src={img2} alt="Car Roadside Assistance In Ahmedabad" className="iconss" />
                  </div>
                  <div className="right">
                    <h4 className="item-name">Quickest Response Time</h4>
                    <p className="paras">
                      Usually our customers get a mechanic in just 30 minutes.
                      And our support representative is always available to take
                      your calls
                    </p>
                  </div>
                </div>

                <div className="item">
                  <div className="left">
                    <img src={img3} alt="Commercial Road Service Near Me Amritsar" className="iconss" />
                  </div>
                  <div className="right">
                    <h4 className="item-name">Highly Rated</h4>
                    <p className="paras">
                      Our app has 95%+ 5-star ratings on Play Store and the
                      customers always love our services
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="left">
                    <img src={img4} alt="Honda Roadside Assistance In Amritsar" className="iconss" />
                  </div>
                  <div className="right">
                    <h4 className="item-name">Instantaneous support</h4>
                    <p className="paras">
                      It takes only around 30 minutes for a mechanic to reach
                      you. They are available 24/7 for you
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="app-container">
              <img src={appsimg} alt="My Car Roadside Assistance In Amritsar" className="Apps" />
              <div className="app-container-box">
                <h3 className="head">
                  Download Our App for <br /> Any Devices:
                </h3>
                <a
                  href="https://play.google.com/store/apps/details?id=com.roadserve.roadservepanel"
                  className="button"
                >
                  <BsGooglePlay className="icons" /> Download for Android
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Service;
