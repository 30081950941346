import React, { useContext, useEffect } from "react";
import { AiTwotoneCalendar } from "react-icons/ai";
import { GoHash } from "react-icons/go";
import { LiaTagSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import { Storage } from "../../../../App";

function BlogContainer() {
  const navigate = useNavigate();
  const { postblogItem } = useContext(Storage);
  const redirect_haldler = (id, heading) => {
    const remove_space = heading.split(" ").join('-');
    const remove_question = remove_space.split("?").join('-');
    const remove_slash = remove_question.split("/").join('-');
    const remove_and = remove_slash.split("&").join('-');
    navigate(`/blog/details/search=${remove_and}/id=${id}`);
  }
  
  return (
    <div className="blog-items-container">
      {postblogItem.length > 0
        ? postblogItem.map((items, index) => (
          <div className="item-box" key={index}>
             <img alt={items.alt_tag} className="imgs" src={`https://admin.roadserve.in/${items?.images[0]?.split("/code")[1]}`}/>
            <div className="box-details">
              <a href={`https://roadserve.in/blog/details/search=${items.heading}/id=${items._id.$oid}`} target="_blank" className="heading">{items.heading}</a>
              <div className="time-spam">
                <p className="para"></p>
                <p className="para">
                  <AiTwotoneCalendar className="icons" />
                  {items.timestamp}
                </p>
              </div>
              <div className="tags">
                <LiaTagSolid />
                {items.tags.map((tag, index) => (
                  <p className="paras" key={index}>
                    <GoHash className={`i${index + 1}`} />
                    {tag.toLowerCase()}
                  </p>
                ))}
              </div>
              <button className="btn" onClick={() => redirect_haldler(items._id.$oid, items.heading)}>
                Read More
              </button>
            </div>

          </div>
        )) : (
          <h4 style={{marginTop : "1rem"}}>Coming Soon...</h4>
        )
      }
    </div>
  );
}
export default BlogContainer;
