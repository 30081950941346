import React, { useState } from 'react';
import Outer from './outer.png';
import inner from './inner.png';
import './Loder.scss';

function Loder() {

    return (
        <div className='loder-box-components'>
            <div className='loder-container'>
                <img src={Outer} alt='outer'className='outer-spinner'/>
                <img src={inner} alt='inner' className='inner-image'/>
            </div>
        </div>
    )
}

export default Loder;