import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, createContext, useEffect } from "react";
import { User_Token, UserData_Url } from "./API/API";
import UserNavbar from "./UserPanel/Components/AppBar/UserNavbar";
import CustomerNavbar from "./UserPanel/Components/AppBar/CustomerNavbar";

// User============================
import UserDashbord from "./UserPanel/Pages/UserDashbord";
import PageNotFound from "./UserPanel/Pages/PageNotFound";
import WelllcomePopUp from "./UserPanel/Components/Popup/WelllcomePopUp";
import PrivacyPolicyPage from "./UserPanel/Pages/PrivacyPolicyPage";
import AboutPage from "./UserPanel/Pages/AboutPage";
import ServicesPage from "./UserPanel/Pages/ServicesPage";
import PricingsPage from "./UserPanel/Pages/PricingPage";
import ContactPage from "./UserPanel/Pages/ContactPage";
import ThankYou from "./UserPanel/Pages/ThankYou";
import BlogPage from "./UserPanel/Pages/BlogPage";
import BlogDetailsPage from "./UserPanel/Pages/BlogDetailsPage";
import JumpStartPage from "./UserPanel/Pages/JumpStartPage";
import GetACallPage from "./UserPanel/Pages/GetACallPage";
import TermsConditionPage from "./UserPanel/Pages/TermsConditionPage";

// BlogPage=======================
import AdminBlogPage from "./AdminPanel/AdminBlogPage";

// Payment============================
import VerifyNumber from "./UserPanel/Payment/VerifyNumber/VerifyNumber";
import VerifyOtp from "./UserPanel/Payment/VerifyOTP/VerifyOtp";
import VerifyDetails from "./UserPanel/Payment/VerifyDetails/VerifyDetails";
import Vouchar from "./UserPanel/Payment/Vouchar/Vouchar";
import CheckStatus from "./UserPanel/Payment/CkeckStatus/CheckStatus";
import StatusPage from "./UserPanel/Payment/StatusPage/StatusPage";
import TrackingPage from "./UserPanel/Tracking/TrackingPage";
import ChooseMethod from "./UserPanel/Payment/ChooseMethod/ChooseMethod";

// Customer========================
// import UserProtected from "./UserPanel/Components/ProtectedRout/UserProtected";
// import Home from "./CustomerPanel/Pages/Home";
// import Mechanic from "./CustomerPanel/Pages/Mechanic";
// import Shop from "./CustomerPanel/Pages/Shop";
// import History from "./CustomerPanel/Pages/History";
// import Profile from "./CustomerPanel/Pages/Profile";
// import Notification from "./CustomerPanel/Pages/Notification";
// import EditProfile from "./CustomerPanel/Pages/EditProfile";
// import SearchMechanic from "./CustomerPanel/Pages/SearchMechanic";
// import Information from "./CustomerPanel/Pages/Information";
// import Mechanics from "./CustomerPanel/Pages/Mechanics";
// import MechanicDetails from "./CustomerPanel/Pages/MechanicDetails";
// import MyMechanic from "./CustomerPanel/Pages/MyMechanic";
// import Cart from "./CustomerPanel/Pages/Cart";
// import Invoice from "./CustomerPanel/Pages/Invoice";

import OTPpop from "./CustomerPanel/Pages/OTPPopup";
import ServicePopUp from "./CustomerPanel/Pages/ServicePopUp";
import axios from "axios";

// Data-Storage====================
export const Storage = createContext();

function App() {
  const [userlog, setUserlog] = useState(localStorage.getItem(User_Token));
  const [popupMessage, setPopupMessage] = useState(
    localStorage.getItem("Message")
  );
  const [pagename, setPagename] = useState("Home");
  const [popup, setpopup] = useState("");
  const [userdata, setUserdata] = useState(false);
  const [nearbyMech, setNearbyMech] = useState();
  const [myMech, setMyMech] = useState();
  const [wellcome, setWellcome] = useState("wellcome-hide");
  const [serviceId, setServiceId] = useState(0);
  const [pageIs, setPageIs] = useState(false);
  const [popVali, setPopVali] = useState(false);

  const [category, setCategory] = useState(false);
  const [blogItems, setBlogItems] = useState(false);
  const [catLog, setCatLog] = useState("All");
  const [tagsLog, setTagsLog] = useState();
  const [tagId, setTagId] = useState(false);
  const [page, setPage] = useState(1);

  // Payment Getway Data
  const [payment, setPayment] = useState();
  const payment_handler = (value) => {
    setPayment(value);
  };
  // User Data function
  function fetchUserData() {
    const Token = localStorage.getItem(User_Token);
    if (userlog) {
      axios
        .get(UserData_Url, {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        })
        .then((res) => {
          console.log("User data res: ", res.data.data);
          setUserdata(res.data.data);
        })
        .catch((err) => {
          console.log("User data err: ", err);
        });
    }
  }
  useEffect(() => {
    setPopupMessage(localStorage.getItem("Message"));
  }, []);
  useEffect(() => {
    fetchUserData();
  }, [userlog]);
  const myMech_handler = (value) => {
    // My-Mechanics
    setMyMech(value);
  };
  const Userlog_Handler = (value) => {
    // Userlog handler
    setUserlog(value);
  };
  const pageName_handler = (value) => {
    // PageName Handler
    setPagename(value);
  };
  const WlcCls_Handler = (value) => {
    // wellcome popup handler
    setWellcome(value);
  };
  const service_click_handler = (value) => {
    // service-click-handler
    setServiceId(value);
  };
  const page_Name_handler = (value) => {
    // User-PageName handler
    setPageIs(value);
  };
  function Autopop() {
    // Contact-auto-popup-function
    if (pageIs !== "Plans") {
      setWellcome("wellcomePopUp-components");
    } else {
      setWellcome(false);
    }
  }
  useEffect(() => {
    if (!popVali) {
      if (!userlog) {
        if (!popupMessage) {
          if (pageIs === "Home" || pageIs === "Service" || pageIs === "About") {
            setTimeout(Autopop, 10000);
            setPopVali(true);
          } else {
            clearTimeout(Autopop);
          }
        }
      }
    }
  }, [pageIs]);
  const BlogItem_Handler = (value) => {
    // BlogItem_Handler
    setBlogItems(value);
  };
  const category_Handler = (value) => {
    // category_Handler
    setCategory(value);
  };
  const CatLog_handler = (value) => {
    // CatLog_handler
    setCatLog(value);
  };
  const tags_handler = (value) => {
    // tags
    setTagsLog(value);
  };
  const catLog_handler = (value) => {
    setTagId(value);
  };
  return (
    <Storage.Provider
      value={{
        // send======
        postUserlog: userlog,
        postPagename: pagename,
        postUserData: userdata,
        postNearbyMech: nearbyMech, // for mech page
        postMyMech: myMech, //for mech page
        postServiceId: serviceId, // for service page
        postPage: pageIs, // for popup
        postblogItem: blogItems, // for Blog Page
        postcategory: category, // for Blog Page
        postCatLog: catLog,
        postTag: tagsLog,
        posttagId: tagId,
        postPayment: payment,
        postPage : page,

        // recived===
        getUserlog: Userlog_Handler,
        getPagename: pageName_handler,
        getpopup: setpopup,
        getmymech: myMech_handler,
        getServiceId: service_click_handler, // for service page
        getPage: page_Name_handler, // for popup
        getBlogItem: BlogItem_Handler, // blog list
        getcategory: category_Handler, // for Blog Page
        getCatLog: CatLog_handler,
        getTagsLog: tags_handler,
        gettagId: catLog_handler,
        getPayment: payment_handler,
        getPage : setPage,
      }}
    >
      <div className="App">
        <BrowserRouter>
          {userlog ? <CustomerNavbar /> : <UserNavbar />}
          <Routes>
            <Route path="/" element={<UserDashbord />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/service" element={<ServicesPage />} />
            <Route path="/pricing" element={<PricingsPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/policy" element={<PrivacyPolicyPage />} />
            <Route path="/thank-you" element={<ThankYou />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/terms-condition" element={<TermsConditionPage />} />
            <Route
              path="/blog/details/:search/:id"
              element={<BlogDetailsPage />}
            />
            <Route path="/jumpstart" element={<JumpStartPage />} />
            <Route path="/get-call" element={<GetACallPage />} />
            <Route path="/admin/blog" element={<AdminBlogPage />} />
            <Route path="/admin/blog/:is" element={<AdminBlogPage />} />
            <Route path="/admin/blog/:postId" element={<AdminBlogPage />} />

            <Route path="/plans/:plan/:method" element={<VerifyNumber />} />
            <Route
              path="/plans/:plan/:method/:number/:whatsapp"
              element={<VerifyOtp />}
            />
            <Route
              path="/verify/:details/:plan/:number"
              element={<VerifyDetails />}
            />
            <Route path="/payment/invoice/:plan" element={<Vouchar />} />
            <Route path="/payment/:status" element={<StatusPage />} />
            <Route path="/payment/check-status" element={<CheckStatus />} />
            <Route path="/user/details/:id" element={<TrackingPage />} />

            <Route path="/plans/:plan/method" element={<ChooseMethod />} />

            {/* <Route path="/response" element={<RedirectPage/>}/> */}
            {/* <Route path="/login" element={<LoginPage />} /> */}

            {/* <Route element={<UserProtected />}>
              <Route path="/user/home" element={<Home />} />
              <Route path="/user/mechanic" element={<Mechanic />} />
              <Route path="/user/shop" element={<Shop />} />
              <Route path="/user/history" element={<History />} />
              <Route path="/user/profile" element={<Profile />} />
              <Route path="/user/notification" element={<Notification />} />
              <Route path="/user/edit-profile" element={<EditProfile />} />
              <Route path="/user/search-mech" element={<SearchMechanic />} />
              <Route path="/user/information" element={<Information />} />
              <Route path="/user/mechanics" element={<Mechanics />} />
              <Route path="/user/mech-details" element={<MechanicDetails />} />
              <Route path="/user/my-mechanic" element={<MyMechanic />} />
              <Route path="/user/cart" element={<Cart />} />
              <Route path="/user/invoice" element={<Invoice />} />
            </Route> */}

            <Route path="*" element={<PageNotFound />} />
          </Routes>

          <WelllcomePopUp postWlcCls={wellcome} getWlcCls={WlcCls_Handler} />

          {popup === "otp" ? (
            <OTPpop />
          ) : popup === "service" ? (
            <ServicePopUp />
          ) : null}
        </BrowserRouter>
      </div>
    </Storage.Provider>
  );
}

export default App;
