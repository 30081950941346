import React from "react";
import './Counter.scss';
import CountUp from "react-countup";
function Counter() {
  return (
    <div className="counter-components">
      <div className="counter-container">
        <div className="items">
          <h3><CountUp duration={10} className="counter" end={2}/>+</h3>
          <p>Year of Experience</p>
        </div>
        <div className="items">
          <h3><CountUp duration={10} className="counter" end={2}/>K+</h3>
          <p>Number of Mechanics</p>
        </div>
        <div className="items">
          <h3><CountUp duration={10} className="counter" end={6}/>K+</h3>
          <p>Number of Orders</p>
        </div>
        <div className="items">
          <h3><CountUp duration={10} className="counter" end={7}/>K+</h3>
          <p>Number of Happy Customers</p>
        </div>
      </div>
    </div>
  );
}

export default Counter;
