import React from "react";
import { AiFillCar, AiFillCreditCard } from "react-icons/ai";
import { MdLocationOn } from "react-icons/md";
import './ServiceCar.scss';

function Servicecar() {
  return (
    <div className="works-componentsis" id="works">
      <div className="work-container">
        <div className="container-head">
          <h3>How to Service your Car Mechanics</h3>
          <p className="pera">
            Roadserve Provides 24x7 Roadside Assistance Accross PAN India
          </p>
        </div>
      </div>

      <div className="blank"></div>

      <div className="work-container-body">
        <div className="body-container">
          <div className="items-1 item-box">
            <div className="box-icon">
              <span className="count">1</span>
              <AiFillCar className="icons" />
            </div>

            <p className="item-head">Get Subscribed to a Plan</p>
            <p className="item-details">
              Choose a Subscription plan that suits you the best
            </p>
          </div>

          <div className="items-2 item-box">
            <div className="box-icon">
              <span className="count">2</span>
              <AiFillCreditCard className="icons" />
            </div>

            <p className="item-head">Get RoadServe Card</p>
            <p className="item-details">
              You'll get a personal RSA Card Delivered to you
            </p>
          </div>

          <div className="items-3 item-box">
            <div className="box-icon">
              <span className="count">3</span>
              <MdLocationOn className="icons" />
            </div>

            <p className="item-head">Get Help Anywhere</p>
            <p className="item-details">
              Use Our App or Call the toll-free Helpline Number
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Servicecar;
