import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { Category_URL, AddBlog_URL, AddImg_URL, DeleteBlog_URL } from "../../API/API";
import EditBox from "../Container2/EditBox";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const schema = yup.object({
  heading: yup.string().required(),
  images: yup.mixed().required(),
  alttag: yup.string().required(),
  tag: yup.string().required(),
  category: yup.string().required(),
  head_tag: yup.string().required(),
}).required();

function createNotification(type) {
  switch (type) {
    case 'info':
      NotificationManager.info('Info message');
      break;
    case 'success':
      NotificationManager.success('Success message', 'added Successfully');
      break;
    case 'warning':
      NotificationManager.warning('Warning message', 'Enter Valid Data', 3000);
      break;
    case 'error':
      NotificationManager.error('Error message', 'Sorry! Try Again', 3000);
      break;
  }
};

function AddPost() {
  const [contant, setContant] = useState(false);
  const [category, setCategory] = useState(false);
  const [metaTag, setMetaTag] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const { register, handleSubmit, reset } = useForm({ resolver: yupResolver(schema) });

  function callApis() {
    axios.get(Category_URL)
      .then((res) => {
        setCategory(res.data)
      })
      .catch((err) => {
        console.log("err : ", err)
      })
  }

  useEffect(() => {
    callApis()
  }, [])


  const onSubmit = (data) => {
    setSpinner(true)
    const token = localStorage.getItem("admin_token");
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    }
    const tags = data.tag.split(",");
    if (contant) {
      const ItemData = {
        heading: data.heading,
        content: contant,
        category: data.category,
        tags: tags,
        alt_tag: data.alttag,
        head_tag: data.head_tag,
      }
      const formData = new FormData();
      formData.append('images', data.images[0], data.images[0].name);
      axios.post(AddBlog_URL, ItemData, config)
        .then((res) => {
          const id = res.data.post_id;
          axios.post(`${AddImg_URL}${id}`, formData, config)
            .then((res) => {
              setContant(false);
              setMetaTag([]);
              reset();
              createNotification('success')
              setSpinner(false);
            })
            .catch((err) => {
              console.log("add img err :", err);
              createNotification('error');
              axios.delete(`${DeleteBlog_URL}${id}`, config)
                .then(res => {
                  setSpinner(false);
                })
                .catch(err => {
                  console.log("blog delete err :", err);
                  setSpinner(false);
                })
            })
        })
        .catch((err) => {
          createNotification('error')
          setSpinner(false)
          console.log("Add Blog err :", err)
        })

    } else {
      createNotification('warning');
      setContant(false);
    }

  }

  const contant_handler = (value) => {
    setContant(value);
  }


  return (
    <div className="add-post-container">
      <h3>Add Blog</h3>
      <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
        <Form.Label>The images you add should not exceed the size of 1mb</Form.Label>
        <Form.Control aria-label="Dollar amount (with dot and two decimal places)" name="heading" placeholder="Enter Blog heading" {...register("heading")} required />

        <EditBox getContant={contant_handler} />

        <Form.Control aria-label="Dollar amount (with dot and two decimal places)" name="tag" placeholder="Enter Blog Tag1, Tag2, Ect" {...register("tag")} required />

        <div className="select-box">
          <Form.Select size="lg" {...register("category")} name="category" required>
            <option value="false">Select Category</option>
            {
              category.length > 0 ? (
                category.map((item, index) => (
                  <option value={item.category_id} key={index}>{item.name}</option>
                ))
              ) : null
            }
          </Form.Select>
          <Form.Control type="file" name="images" {...register("images")} required />
        </div>

        <div className="select-box">
          <Form.Control name="alttag" placeholder="Enter Image Alt Tag" {...register("alttag")} required style={{ width: "50%" }} />
          <Form.Control name="head_tag" as="textarea" placeholder="Enter Your Head Tags" {...register("head_tag")} required />
        </div>

        {
          spinner ? (
            <Button variant="primary" type="submit">
              Sending...
            </Button>
          ) : (
            <Button variant="primary" type="submit">
              Send
            </Button>
          )
        }


      </form>
      <NotificationContainer />
    </div>
  );
}

export default AddPost;
