import React from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './Terms.scss';
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";

function Terms() {
  const navigate = useNavigate();

  return (
    <div className="terms-condition-components">
      <div className="terms-container">
        <ArrowBackIcon  className="icons" onClick={() => navigate('/')}/>
        <div className="heading-container">
          <h3 className="head-tag">Terms & Conditions</h3>
        </div>
        <div className="body-container">
          <ol>
            <li>
              Helpline services are for the vehicle and not for the person.
            </li>
            <li>
              Helpline service is available in Municipal limits of the
              city/cities listed under this service contract.
            </li>
            <li>
              Pan India / National coverage means the services are available
              across all cities and highways as listed on our
              Application/website. The company, however, has the rights to
              discontinue services in any given city and or highway without
              prior intimation to its clients. The registered clients shall have
              no claim therefore.
            </li>
            <li>
              Deals and discounts are offered by garages and other partners on
              their own for the promotion of their respective brands. Roadserve
              does not guarantee the quality of service and is not responsible
              for any claims and/ or damages that could arise, if any, from
              availing these offers and deals.
            </li>
            <li>
              Vehicle-to-vehicle towing is dangerous, and may cause further
              damage to the vehicles, hence, we discourage vehicle-to-vehicle
              towing unless deemed required. Towing shall be provided by Cranes
              and the charges for the same shall be borne by the client as per
              actual.
            </li>
            <li>
              It is mandatory for the customer to produce the membership details
              to avail the services. If the vehicle is repairable on road,
              towing/dropping will not be provided. Towing shall be provided to
              the nearest service station by our Service Vans only to those
              vehicles which are easily towable at additional cost. For heavy
              vehicles, towing shall be provided by crane at additional cost.
              Rates as per agreed by the clients.
            </li>
            <li>
              Fuel up to 5 litres at actual cost will be provided under the case
              of emergency. Fuel rate is subject to the rate of fuel in the
              respective city and availability .
            </li>
            <li>
              CNG and other gases/octane fuel is not provided under this service
              agreement.
            </li>
            <li>
              TTube repair/tube less tyre repair services shall be provided at
              actual cost, depending on total number of punctures in the
              tube/tube less tyre, the per puncture repair cost is variable and
              depends upon area to area. Service Charge will not be payable
            </li>
            <li>
              Company will try and ensure to provide service within the
              stipulated time of 30 minutes; however, the company is not
              responsible for any delay caused by conditions beyond the control
              of company like traffic jams, riots, road block, route diversions,
              mechanic location, rain, any natural calamities, rallies, etc.
            </li>
            <li>
              In case of misuse of services, non-payment of services charges,
              misbehaviour, helpline services shall/can be cancelled/stopped
              without any prior notice. The company reserves the right to do so
              without prior intimation.
            </li>
            <li>
              Company is authorized to add or change any or all the terms and
              conditions at its own discretion at any time without prior
              intimation.
            </li>
            <li>
              The company shall not be liable for any claims/refunds whatsoever.
              Rarest claims in extreme cases if any shall not exceed the
              residual value on per day prorate basis of registration period of
              the services fees paid by the customer. The damages and claim if
              any shall not be more than the amount paid by the client.
            </li>
            <li>
              For similar faults, services will not be provided more than twice
              consecutively. Helpline service usage i.e., number of available
              service and tenure i.e., validity is subject to the service
              package subscribed by the customer. The same varies from city to
              city and are different subject to annual packages.
            </li>
            <li>
              The Basic plan is valid for 2 years and 2 services. The Advance
              plan is valid for 4 years and 3 services. The ultimate plan is
              valid for 15 years and 5 services. After that, a renewal fee will
              be applied as per your plan.
            </li>
            <li>
              The customer or his representative will have no claim against the
              company or its representative either civil, financial or criminal
              in nature pursuant to this helpline service in respect of death or
              injury of the customer or any other person or loss or damage to
              any other property caused by or due to equipment failure,
              negligence, breakdown, accident or fire on utilization of services
              during the validity of helpline services or at such time while the
              vehicle is under the process of repairs carried out by the
              company's representatives.
            </li>
            <li>
              Company reserves the right to file a claim/suit against the party
              / person / company whose cheque gets dishonoured for whatsoever
              reason. Membership will be activated only after additional payment
              towards cheque bouncing charge.
            </li>
            <li>
              If helpline service is availed without card activation or
              non-availability of card, a service charge of Rs 1000 will be
              charged. (Non-Refundable).
            </li>
            <li>
              Helpline service is not valid for any commercial vehicle unless
              approved by the service provider under a separate term sheet.
            </li>
            <li>
              User can avail the service after 48 hours of membership
              activation.
            </li>
            <li>One membership is valid for one vehicle only.</li>
            <li>All disputes subject to jurisdiction of Delhi only.</li>
          </ol>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Terms;
