import React, { useEffect, useState } from "react";
import "./AdminBlog.scss";
import LoginBox from "./Container/LoginBox";
import NavContainer from "./Container/NavContainer";
import AddPost from "./Container/AddPost";
import CattegoryTags from "./Container/CattegoryTags";
import AllIBlog from "./Container/AllIBlog";
import { useNavigate } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';


function createNotification(type) {
  switch (type) {
    case 'info':
      NotificationManager.info('Info message');
      break;
    case 'success':
      NotificationManager.success('Success message', 'Logout Successfully', 3000);
      break;
    case 'warning':
      NotificationManager.warning('Warning message', 'Enter Valid Data', 3000);
      break;
    case 'error':
      NotificationManager.error('Error message', 'Sorry! Try Again', 3000);
      break;
  }
};



function AdminBlogPage() {
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem("admin_token"));
  const [bordName, setBordname] = useState("All Blog");
  const [bordIs, setBordIs] = useState();
  const log_handle = (value) => { setToken(value) };
  const bord_handler = (value) => { setBordname(value) };

  // const {page} = useParams();

  useEffect(() => {
    if (bordName === "Logout") {
      localStorage.removeItem("admin_token");
      setToken(false);
      createNotification('success');
      navigate('/');
    } else if (bordName === "Add Post") {
      setBordIs(<AddPost />)
      navigate('/admin/blog/is=addpost');
    } else if (bordName === "All Blog") {
      navigate('/admin/blog/is=allblog');
      setBordIs(<AllIBlog />)
    } else if (bordName === "Category & Tags") {
      setBordIs(<CattegoryTags />)
      navigate('/admin/blog/is=cat&tags');
    }
  }, [bordName])

  return (
    <div className="admin-blog-pages">
      <div className="admin-blog-container">
        <div className="nav-contant">
          {!token ? <LoginBox setTokens={log_handle} /> : <NavContainer setBord={bord_handler} />}
        </div>

        <div className="bord-container">
          {token ? bordIs : "Please Login First!"}
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
}

export default AdminBlogPage;
