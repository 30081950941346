import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SendMail_URL } from "../../../API/API";
import axios from "axios";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import "../../Scss/Navbar.scss";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

const schema = yup
  .object({
    name: yup.string().required("Name is a required field"),
    email: yup.string().required("Email is a required field"),
    phone: yup.string().required("Number is a required field"),
    message: yup.string().required("Message is a required field"),
  })
  .required();

function ContactPopup(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [canditionval, setConditionval] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (canditionval) {
      if(data.phone.length === 10){
        setLoading(true)
      axios
        .post(SendMail_URL, {
          name: data.name,
          phone: data.phone,
          email: data.email,
          subject: "New Contact",
          message: data.message,
        })
        .then((res) => {
          reset();
          props.recStatus("focus-hide");
          setLoading(false);
          navigate("/thank-you");
        })
        .catch((err) => {
          console.log("Subscribe err : ", err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: "try Again",
          });
          props.recStatus("focus-hide");
        });
      }else{
        alert('Type Valid Mobile Number')
      }
      
    }
  };

  return (
    <div className={`contact-popup-components  ${props.sendStatus}`}>
      <div className="popup-container">
        <form
          className="form-container"
          onSubmit={handleSubmit(onSubmit)}
          id="form"
        >
          <p className="form-head">Let's Connect</p>
          <CloseIcon
            className="icons"
            onClick={() => props.recStatus("focus-hide")}
          />
          <div className="group-input-box">
            <div className="input-field-1">
              <input
                type="text"
                placeholder=" Your Name"
                name="name"
                {...register("name")}
              />
              <p>{errors.name?.message}</p>
            </div>
            <div className="input-field-1">
              <input
                type="email"
                placeholder=" Your Email"
                name="email"
                {...register("email")}
              />
              <p>{errors.email?.message}</p>
            </div>
          </div>

          <div className="input-field">
            <input
              type="number"
              placeholder=" Your Number"
              name="phone"
              {...register("phone")}
            />
            <p>{errors.phone?.message}</p>
          </div>
          <div className="textarea-box">
            <textarea
              name="message"
              placeholder=" How can we help"
              {...register("message")}
            ></textarea>
            <p>{errors.message?.message}</p>
          </div>
          <div className="form-footer">
            <div className="input-field-3">
              <label>
                <input
                  type="checkbox"
                  value="option2"
                  name="conditions"
                  onChange={(e) => setConditionval(e.target.value)}
                />
                I agree to recieve newsletters
              </label>
            </div>
            {loading ? (
              <button className="button-loder">
                Sending
                {
                  <ThreeDots
                    radius="3"
                    color="#fff"
                    visible={true}
                    height="20"
                    width="50"
                  />
                }
              </button>
            ) : (
              <button className="button">Send Message</button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactPopup;
