import React,{useContext} from "react";
import sericon from "../../Images/Icons/servpopup.png";
import { Storage } from "../../App";
function Servicepoup() {
    const {getpopup} = useContext(Storage);
  return (
    <div className="serpopup-components">
      <div className="serpopup-container">
        <div className="popup-box">
          <h3>Do you want this service for your car?</h3>
          <div className="items">
            <img src={sericon} alt="Service" />
            <div className="details">
              <h4>Towing</h4>
              <p>150₹/hr</p>
            </div>
          </div>

          <button
            onClick={() => {
              getpopup(null);
            }}
          >
            Accepted
          </button>
        </div>
      </div>
    </div>
  );
}

export default Servicepoup;
