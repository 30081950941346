import React, { useEffect, useState } from "react";
import FormatQuoteOutlinedIcon from "@mui/icons-material/FormatQuoteOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import 'animate.css';
import "./Testimonial.scss";
import { reviewList } from "../../../Datas/Reviews";

function Testimonial() {
  const [id, setId] = useState(0);
  return (
    <div className="testimonial-components" id="review">
      <div className="testimonial-container">
        <div className="left-container">
          <div className="main-circle-box">
            {
              reviewList.map((item, index) => (
                id === index ? null : <img src={item.client_img} class={`client_img review-img${index}`} key={index} alt="24 7 Roadside Assistance Near Me Mumbai"/>
              ))
            }
            <img src={reviewList[id].client_img} class="client-image-mid" alt="Car Towing Service Near Me Ahmedabad"/>
          </div>
        </div>

        <div className="right-container">
          <div className="box-container">
            <p className="header">Testimonial</p>
            <h3 className="tags">CLIENT’S REVIEW</h3>

            <div className="review">
              <div className="left">
                <FormatQuoteOutlinedIcon className="icons" />
              </div>
              <div className="right">{reviewList[id].review}</div>
            </div>

            <div className="footer">
              <div className="left">
                <p>{reviewList[id].client_name}</p>
                <span>{reviewList[id].client_ocopations}</span>
              </div>
              <div className="right">
                <ArrowBackIosIcon
                  className="icons"
                  onClick={() => {
                    if (id === 0) {
                      setId(reviewList.length - 1);
                    } else {
                      setId(id - 1);
                    }
                  }}
                />
                <ArrowForwardIosIcon
                  className="icons"
                  onClick={() => {
                    if (id === reviewList.length - 1) {
                      setId(0);
                    } else {
                      setId(id + 1);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
