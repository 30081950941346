import React, { useState } from "react";
import "./GetCall.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { GetCall_URL } from "../../../API/API";

const schema = yup
  .object({
    name: yup.string().required(),
    contact_number: yup.string().required(),
    car_number: yup.string().required(),
    whatsapp_consent : yup.string().required(),
  })
  .required();

function GetCall() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (data.contact_number.length === 10) {
      setLoading(true);
      axios.post(GetCall_URL,data)
        .then((res) => {
          // console.log("res : ", res);
          setLoading(false);
          navigate("/thank-you");
          reset();
        })
        .catch((err) => {
          console.log("call back err : ", err);
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: "try Again",
          });
        });
    } else {
      alert("Type Valid Number");
    }
  };
  

  return (
    <div className="get-call-components">
      <div className="get-call-container">
        <div className="form-container">
          <h3 className="heading">Get a call back</h3>
          <form className="form-box" onSubmit={handleSubmit(onSubmit)}>
            <input
              type="text"
              placeholder="Name"
              className="input-field"
              {...register("name")}
              name="name"
              required
            />
            <input
              type="number"
              placeholder="Number"
              className="input-field number"
              {...register("contact_number")}
              name="contact_number"
              required
            />
            <input
              type="text"
              placeholder="Car Number"
              className="input-field"
              {...register("car_number")}
              name="car_number"
              required 
            />
            <div className="check-box">
              <input type="checkbox" className="check" name="whatsapp_consent" {...register("whatsapp_consent")}/>I want to
              receive whatsapp message.
            </div>

            {loading ? (
              <button className="button-loder">
                Sending
                {
                  <ThreeDots
                    radius="3"
                    color="#fff"
                    visible={true}
                    height="20"
                    width="50"
                  />
                }
              </button>
            ) : (
              <button className="button">Send Message</button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default GetCall;
