import React, { useEffect } from "react";
import "./Pricing.scss";
import { AiOutlineHome } from "react-icons/ai";
import { BsGooglePlay } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import backhead from "../../../Images/Background/Pricing-head.png";
import appback from "../../../Images/Background/apps-back.png";
import lightimg from "../../../Images/Icons/light.png";
import basicimg from "../../../Images/Icons/basicp.png";
import advanceimg from "../../../Images/Icons/advance.png";
import ultimate from "../../../Images/Icons/ultimate.png";
import { FcOk } from "react-icons/fc";
import { AiFillCloseCircle } from 'react-icons/ai';

function Pricing() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pricing-container">
      <div className="header-container">
        <img src={backhead} alt="Car Maintenance Near Me Kolkata" className="backhead" />
        <div className="about-heading-text">
          <h3 className="head-text">Choose your plan?</h3>
          <div className="page-name-links">
            <AiOutlineHome className="icon" />
            <p onClick={() => navigate("/")}>Home / </p>
            <p>Pricing</p>
          </div>
        </div>
      </div>

      <div className="pricing-box-container">
        <div className="heading-column">
          <div className="top-box">
            <p className="heads">Pricing</p>
            <h3 className="tags">
              The Best Plan
              <br />
              For You
            </h3>
            <p className="paras">
              Subscription packages which suits your car as well as your pocket.
            </p>
          </div>
          <div className="bottom-box">
            <table>
              <tr>
                <td>24*7 On-Road Assistance</td>
              </tr>
              <tr>
                <td>Jumpstart in case of week/dead battery</td>
              </tr>

              <tr>
                <td>Doorstep vehicle insrance renewal service</td>
              </tr>
              <tr>
                <td>Assistance for punctured tyre</td>
              </tr>
              <tr>
                <td>Arrengement of flatbed trucks & winching services</td>
              </tr>
              <tr>
                <td>Tele assistance for minor repairs</td>
              </tr>
              <tr>
                <td>City route and map guidance over phone</td>
              </tr>
              <tr>
                <td>Urgent relay of message</td>
              </tr>
              <tr>
                <td>Medical co-ordinary</td>
              </tr>
              <tr>
                <td>On the Spot Repairing for minor faults</td>
              </tr>
              <tr>
                <td>On-Road key Lockout Assistance</td>
              </tr>
              <tr>
                <td>Battery, coolant & others parts act discount price</td>
              </tr>

              <tr>
                <td>Punctures tyre repair in case two flat tyres</td>
              </tr>

              <tr>
                <td>Fuel upto 5 Ltrs in case of running dry</td>
              </tr>
              <tr>
                <td>Transfer option (maximum upto 3 vehicle only)</td>
              </tr>
              <tr>
                <td>Number of services</td>
              </tr>
              <tr>
                <td>Physical Card</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="plan-column">
          <div className="top-box">
            <img src={lightimg} alt="Car Tochan Service In Ahmedabad" className="lightimg" />
            <p className="plan-head">Light Plan</p>
            <div className="pricing">
              <h3 className="amount">
                <p className="rs">₹</p>999
              </h3>
              <p className="years">/ 1 yr</p>
            </div>
            <div className="pricing">
              <h3 className="amount">
                <p className="rs"></p>
              </h3>
              <p className="years"></p>
            </div>
            <p className="tags"></p>
            <button
              className="button"
              onClick={() => {
                navigate('/plans/plan=light/method');
              }}
            >
              Choose plan
            </button>

            {/* <a href="https://roadserve.in/payment/light"
              className="button"
            >
              Choose plan
            </a> */}

          </div>
          <div className="bottom-box">
            <table>
              <tr>
                <td><FcOk className="icon-ok" /></td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td><FcOk className="icon-ok" /></td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td><FcOk className="icon-ok" /></td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td><FcOk className="icon-ok" /></td>
              </tr>
              <tr>
                <td>
                  <AiFillCloseCircle className="icon-cancel" />
                </td>
              </tr>
              <tr>
                <td><AiFillCloseCircle className="icon-cancel" /></td>
              </tr>
              <tr>
                <td>
                  <AiFillCloseCircle className="icon-cancel" />
                </td>
              </tr>
              <tr>
                <td><AiFillCloseCircle className="icon-cancel" /></td>
              </tr>
              <tr>
                <td><AiFillCloseCircle className="icon-cancel" /></td>
              </tr>
              <tr>
                <td><b>2</b></td>
              </tr>
              <tr>
                <td><b>On additional Rs 300</b></td>
              </tr>
            </table>
          </div>
        </div>

        <div className="plan-column">
          <div className="top-box">
            <img src={basicimg} alt="Car Roadside Assistance In Ahmedabad" className="lightimg" />
            <p className="plan-head">Basic Plan</p>
            <div className="pricing">
              <h3 className="amount">
                <p className="rs">₹</p>1999
              </h3>
              <p className="years">/ 2 yr</p>
            </div>
            <div className="pricing">
              <h3 className="amount">
                <p className="rs"></p>
              </h3>
              <p className="years"></p>
            </div>
            <p className="tags"></p>
            <button
              className="button"
              onClick={() => {
                navigate('/plans/plan=basic/method');
              }}
            >
              Choose plan
            </button>

            {/* <a href="https://roadserve.in/payment/basic"
              className="button"
            >
              Choose plan
            </a> */}

          </div>
          <div className="bottom-box">
            <table>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td><AiFillCloseCircle className="icon-cancel" /></td>
              </tr>

              <tr>
                <td><AiFillCloseCircle className="icon-cancel" /></td>
              </tr>

              <tr>
                <td><AiFillCloseCircle className="icon-cancel" /></td>
              </tr>
              <tr>
                <td><AiFillCloseCircle className="icon-cancel" /></td>
              </tr>
              <tr>
                <td><b>4</b></td>
              </tr>
              <tr>
                <td>
                  <b>Free</b>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div className="plan-column">
          <div className="top-box">
            <img src={advanceimg} alt="Hyundai Road Side Assistance In Amritsar" className="lightimg" />
            <p className="plan-head">Advance plan</p>
            <div className="pricing">
              <h3 className="amount">
                <h3 className="amount-throw">₹ 3999 / 3 yr</h3>
              </h3>
            </div>
            <div className="pricing">
              <h3 className="amount">
                <p className="rs">₹</p>3999
              </h3>
              <p className="years">/ 4 yr</p>
            </div>

            <p className="tags">*Extra 300 off when you pay online</p>

            <button
              className="button"
              onClick={() => {
                navigate('/plans/plan=advance/method');
              }}
            >
              Choose plan
            </button>

            {/* <a href="https://roadserve.in/payment/advance"
              className="button"
            >
              Choose plan
            </a> */}

          </div>
          <div className="bottom-box">
            <table>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td><AiFillCloseCircle className="icon-cancel" /></td>
              </tr>
              <tr>
                <td><b>7</b></td>
              </tr>
              <tr>
                <td>
                  <b>Free</b>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div className="plan-column">
          <div className="top-box">
            <img src={ultimate} alt="On Road Car Service In Bhopal" className="lightimg" />
            <p className="plan-head">Ultimate Plan</p>
            <div className="pricing">
              <h3 className="amount line-throw">
                <h3 className="amount-throw">₹ 4999 / 12 yr</h3>
              </h3>
            </div>
            <div className="pricing">
              <h3 className="amount">
                <p className="rs">₹</p>4999
              </h3>
              <p className="years">/ 15 yr</p>
            </div>

            <p className="tags">*Extra 300 off when you pay online</p>
            <button
              className="button focus"
              onClick={() => {
                navigate('/plans/plan=ultimate/method');
              }}
            >
              Choose plan
            </button>

            {/* <a href="https://roadserve.in/payment/ultimate"
              className="button"
            >
              Choose plan
            </a> */}

          </div>
          <div className="bottom-box">
            <table>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td>
                  <FcOk className="icon-ok" />
                </td>
              </tr>
              <tr>
                <td><b>30</b></td>
              </tr>
              <tr>
                <td>
                  <b>Free</b>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div className="app-container">
        <img src={appback} alt="Recovery Van Near Me In Bhopal" className="appback" />
        <div className="app-container-box">
          <h3 className="head">
            Download Our App for <br /> Any Devices:
          </h3>
          <a href="https://play.google.com/store/apps/details?id=com.roadserve.roadservepanel" className="button">
            <BsGooglePlay className="icons" /> Download for Android
          </a>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Pricing;
