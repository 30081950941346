import { AiOutlineHome, AiOutlineSetting, AiOutlineShop, AiOutlineHistory, AiOutlineUser } from 'react-icons/ai';

export const ItemsForWeb = [
    {
        id:1,
        name:'Home',
        link:"/",
    },
    {
        id:2,
        name:'About',
        link:"/about",
    },
    {
        id:3,
        name:'Services',
        link:"/service",
    },
    {
        id:4,
        name:'Pricing',
        link:"/pricing",
    },
    {
        id:5,
        name:'Blog',
        link:"/blog",
    },
    {
        id:6,
        name:'Jumpstart',
        link:"/jumpstart",
    },
    {
        id:7,
        name:'Contact',
        link:"/contact",
    },
];

export const ItemsForMobile = [
    {
        id:1,
        name:'Home',
        link:"/",
    },
    {
        id:2,
        name:'About',
        link:"/about",
    },
    {
        id:3,
        name:'Services',
        link:"/service",
    },
    {
        id:4,
        name:'Pricing',
        link:"/pricing",
    },
    {
        id:5,
        name:'Blog',
        link:"/blog",
    },
    {
        id:6,
        name:'Jumpstart',
        link:"/jumpstart",
    },
    {
        id:7,
        name:'Contact',
        link:"/contact",
    },
    // {
    //     id:7,
    //     name:'Login',
    //     link:"/login",
    // }

];

export const CoustomerItems = [
    {
        id: 1,
        name:'Home',
        link:"/user/home",
        icon : <AiOutlineHome className='icons'/>,
    },
    {
        id: 2,
        name:'Mechanic',
        link:"/user/mechanic",
        icon : <AiOutlineSetting className='icons'/>,
    },
    {
        id: 3,
        name:'Shop',
        link:"/user/shop",
        icon : <AiOutlineShop className='icons'/>,
    },
    {
        id: 4,
        name:'History',
        link:"/user/history",
        icon : <AiOutlineHistory className='icons'/>,
    },
    {
        id: 5,
        name:'Profile',
        link:"/user/profile",
        icon : <AiOutlineUser className='icons'/>,
    },
];

