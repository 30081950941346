import React, { useContext, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";

function PaginationComponents(props) {
  const handleChange = (event, value) => {
    props.getPage(value);
  };
  
  return (
    <div className="paginations">
      <Stack spacing={2}>
        <Pagination
          count={props.totalPage}
          page={props.postPage}
          onChange={handleChange}
          color="primary"
        />
      </Stack>
    </div>
  );
}

export default PaginationComponents;
