import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function EditBoxs(props) {
    const [value, setValue] = useState(props.postcontent);
    const [spinner, setSpinner] = useState(props.postcontent);
    const module = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'align': [] }],
            ['link', 'image'],
            [{ 'color': [] }],
            ['clean']
        ],
    }

    useEffect(() => {
        if (value === "<p><br></p>") {
            props.getContant(false);
        } else {
            props.getContant(value);
        }
    }, [value])

    return (
        <div className='editor-container'>
            {
                spinner ? (
                    <ReactQuill
                        theme="snow"
                        onChange={setValue}
                        value={value}
                        modules={module}
                        bounds={'.app'}
                        placeholder="add content for blog"
                    />
                ) : null
            }

        </div>
    )
}

export default EditBoxs