import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Tracking_URL } from '../../API/API';
import axios from 'axios';
import './TrackingPage.scss';
import moment from 'moment';
import Table from 'react-bootstrap/Table';
import Loder from '../../Loder/Loder';



function TrackingPage() {
    const param = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [spinner, setSpinner] = useState(true);

    useEffect(() => {
        if (param.id) {
            const orderId = param.id.split('=')[1];
            if (orderId === "") {
                navigate('/')
            } else {
                axios.get(`${Tracking_URL}${orderId}`)
                    .then((res) => {
                        // console.log("Response is: ", res.data.data);
                        setData(res.data.data);
                        setSpinner(false)
                    })
                    .catch((err) => {
                        setSpinner(false);
                        setData(false);
                        console.log("Error eyeche: ", err);
                    })
            }
        } else {
            navigate('/');
        }
    }, [])
    return (
        <div className='tracking-components'>
            {
                !spinner ? (
                    <div className="tracking-container">
                        {
                            data ? (
                                <Table striped bordered hover className='table-sheet'>
                                    <thead>
                                        <tr>
                                            <th>Order Details</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td>{data.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Contact Number</td>
                                            <td>{data.contact_number}</td>
                                        </tr>
                                        <tr>
                                            <td>Address</td>
                                            <td>{data.address}</td>
                                        </tr>
                                        <tr>
                                            <td>State</td>
                                            <td>{data.state}</td>
                                        </tr>
                                        <tr>
                                            <td>Car Model</td>
                                            <td>{data.car_model}</td>
                                        </tr>
                                        <tr>
                                            <td>Car Number</td>
                                            <td>{data.car_number}</td>
                                        </tr>
                                        <tr>
                                            <td>Order Created at</td>
                                            <td>{moment(data.order_created_datetime.$date).format("DD MM YYYY, HH:MM")}</td>
                                        </tr>
                                        <tr>
                                            <td>Amount</td>
                                            <td>{data.cod_amount}</td>
                                        </tr>
                                        <tr>
                                            <td>Current Status Type</td>
                                            <td>{data.current_status_type}</td>
                                        </tr>
                                        <tr>
                                            <td>Current Status Time</td>
                                            <td>{data.current_status_time}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            ) : (
                                <h3 className="error">Data not found</h3>
                            )
                        }
                    </div>
                ) : <Loder />
            }

        </div>
    )
}

export default TrackingPage;