import React, { useState, useEffect, useContext } from "react";
import "./BlogDetails.scss";
import { AiTwotoneCalendar } from "react-icons/ai";
import { LiaSlackHash } from "react-icons/lia";
import { BsFillTagsFill } from "react-icons/bs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  AiFillSafetyCertificate,
  AiFillMobile,
  AiFillInstagram,
  AiFillFacebook,
  AiFillTwitterSquare,
} from "react-icons/ai";
import { useNavigate , useParams} from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import AppContainer from "../Blog/Container/AppContainer";
import { FaLink } from "react-icons/fa";
import Slider from "./Container/Slider";
import { Storage } from "../../../App";
import { GetDetails_URL, GetTags_URL } from "../../../API/API";
import axios from "axios";
import Loder from "../../../Loder/Loder";
import Parser from 'html-react-parser';
import { Helmet } from "react-helmet";

function BlogDetails() {
  const navigate = useNavigate();
  const {id} = useParams();
  const BlogId = id.split('=')[1];
  const { postcategory, postTag, getTagsLog, gettagId, getCatLog } =
    useContext(Storage);
  const [blogItem, setBlogItem] = useState(false);

  function callBlogApi() {
    axios.get(`${GetDetails_URL}${BlogId}`)
      .then((res) => {
        // console.log("blog details: ", res.data.data[0])
        setBlogItem(res.data.data[0]);
        // const dadas = res.data.data[0];
        // console.log(dadas)
      })
      .catch((err) => {
        console.log("err :", err);
      });
  }

  function callTagsApi() {
    axios
      .get(GetTags_URL)
      .then((res) => {
        // console.log("res :", res.data);
        getTagsLog(res.data);
      })
      .catch((err) => {
        console.log("err :", err);
      });
  }

  useEffect(() => {
    if (!BlogId) {
      navigate("/blog");
    } else {
      callBlogApi();
      callTagsApi();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <>
      <Helmet>
        {blogItem ? (<title>{blogItem.heading}</title>) : null}
        {blogItem ? (Parser(blogItem.head_tag)) : null}
      </Helmet>

      <div className="blog-details-components">
        <ArrowBackIcon className="btn-div" onClick={() => navigate("/blog")} />
        <div className="details-container">
          {blogItem ? (
            <div className="left-container">
              <div className="header-container">
                <div className="img-box">
                  <img
                    src={`https://admin.roadserve.in/${blogItem?.images[0]?.split("/code")[1]}`}
                    alt={blogItem.alt_tag}
                    className="imgs"
                  />
                </div>
                <div className="details-box">
                  <h3 className="heading">{blogItem.heading}</h3>

                  <div className="all-typ-container">
                    <div className="both-container">

                      <div className="category-box">
                        <AiFillSafetyCertificate className="icons" />
                        <p className="para">{blogItem.category.name[0]}</p>
                      </div>

                      <div className="time-spam">
                        <p className="para">
                          <AiTwotoneCalendar className="icons" />
                          {blogItem.timestamp}
                        </p>
                      </div>

                    </div>

                    <div className="tags">
                      <BsFillTagsFill className="icons" />
                      {blogItem.tags.map((item, index) => (
                        <p className="paras" key={index}>
                          <LiaSlackHash className={`i${index + 1}`} />
                          {item}
                        </p>
                      ))}
                    </div>


                  </div>
                </div>
              </div>

              <div className="documents">
                {Parser(blogItem.content)}
              </div>
              <div className="blank-border"></div>


            </div>
          ) : (
            <Loder />
          )}

          <div className="right-container">
            <div className="catagorys-box">
              <h3 className="headers">
                <AiFillSafetyCertificate className="icons" />
                Category
              </h3>

              <div className="items-container">
                <p
                  className="item"
                  onClick={() => {
                    getCatLog("All");
                    navigate("/blog");
                  }}
                >
                  All
                </p>
                {postcategory
                  ? postcategory.map((item, index) => (
                    <p
                      className="item"
                      key={index}
                      onClick={() => {
                        getCatLog(item.category_id);
                        navigate("/blog");
                      }}
                    >
                      {item.name}
                    </p>
                  ))
                  : null}
              </div>
            </div>

            <div className="tags-box">
              <div className="headers">
                <BsFillTagsFill className="icons" />
                Tags
              </div>

              <div className="items-container">
                {postTag
                  ? postTag.map((item, index) => (
                    <p
                      className="paras"
                      key={index}
                      onClick={() => {
                        gettagId(item.tag_id);
                        navigate("/blog");
                      }}
                    >
                      <LiaSlackHash className={`i${index + 1}`} />
                      {item.name.toLowerCase()}
                    </p>
                  ))
                  : null}
              </div>
            </div>

            <div className="social-links-container">
              <h3 className="headers">
                <FaLink className="icons" /> Social
              </h3>

              <div className="items-container">
                <a href="https://www.facebook.com/RoadServeIndia" className="paras">
                  <AiFillFacebook className="icons i1" />
                  Facebook
                </a>

                {/* <p className="paras" onClick={shareFacebook}>
                  <AiFillFacebook className="icons i1" />
                  Facebook
                </p> */}

                <a href="https://www.instagram.com/roadserveindia/" className="paras">
                  <AiFillInstagram className="icons i2" />
                  Instagram
                </a>
                <a href="https://twitter.com/RoadServeIndia" className="paras">
                  <AiFillTwitterSquare className="icons i3" />
                  Twitter
                </a>
              </div>
            </div>

            <div className="ads-container">
              <h3 className="headers">
                <AiFillMobile className="icons" /> App Screen
              </h3>
              <Slider />
            </div>
          </div>
        </div>

        <AppContainer />
        <Footer />
      </div>

    </>



  );
}

export default BlogDetails;
