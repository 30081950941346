import React, {useEffect} from "react";
import backhead from "../../../Images/Background/aboutHead.png";
import { AiOutlineHome } from "react-icons/ai";
import { BsGooglePlay } from "react-icons/bs";
import aboutimg from "../../../Images/Background/about-imgs.png";
import boosticon from "../../../Images/Icons/boost.png";
import clockicon from "../../../Images/Icons/clock.png";
import skills from "../../../Images/Icons/skill.png";
import backImg from "../../../Images/Background/secmid.png";
import mission from "../../../Images/Icons/mission.png";
import vission from "../../../Images/Icons/vission.png";
import history from "../../../Images/Icons/history.png";
import appback from "../../../Images/Background/apps-back.png";
import Footer from "../../Components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import "./About.scss";

function Abouts() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="AboutContainer">
      <div className="header-container">
        <img src={backhead} alt="Best Roadside Assistance Company In Pune" className="backhead" />
        <div className="about-heading-text">
          <h3 className="head-text">Who we are?</h3>
          <div className="page-name-links">
            <AiOutlineHome className="icon" />
            <p onClick={() => navigate("/")}>Home / </p>
            <p>About Us</p>
          </div>
        </div>
      </div>

      <div className="about-mid-containt">
        <div className="mid-container-box">
          <div className="left">
            <img src={aboutimg} alt="Best Roadside Assistance In Pune" className="aboutimg" />
          </div>
          <div className="right">
            <p className="head">About Us</p>
            <h3 className="tags">
              About The <br />
              ROADSERVE
            </h3>
            <p className="para">
              Introducing Roadserve - a premier car assistance company dedicated
              to providing top-notch services to car owners across India.
              Whether it's a flat tire, a dead battery, or a breakdown,{" "}
            </p>
            <p className="para">
              Roadserve's team of experienced professionals is always ready to
              provide prompt and reliable assistance. With a commitment to
              customer satisfaction and a focus on quality service, Roadserve is
              the go-to destination for all your car assistance needs.
            </p>
          </div>
        </div>
      </div>

      <div className="about-sec-mid-container">
        <div className="sec-mid-container">
          <div className="left">
            <h3 className="head">The History of <br /> Roadserve</h3>
            <div className="item-box-container">
              <div className="item-box">
                <div className="left">
                  <img src={boosticon} alt="Car Mechanic Near Me 24 Hours In Kolkata" className="icon" />
                </div>
                <div className="right">
                  <h5 className="head">Since our inception:</h5>
                  <p className="para">we have grown steadily, expanding our operations and
                    building a reputation for excellence in the industry.
                    Through strategic partnerships and collaborations.
                  </p>
                </div>
              </div>

              <div className="item-box">
                <div className="left">
                  <img src={clockicon} alt="" className="icon" />
                </div>
                <div className="right">
                  <h5 className="head">Over the years:</h5>
                  <p className="para">
                    we have continually invested in training and equipping our
                    team with the latest tools and technologies, ensuring we
                    stay at the forefront of the industry.
                  </p>
                </div>
              </div>

              <div className="item-box">
                <div className="left">
                  <img src={skills} alt="Car Mechanic Near Me Home Service In Kolkata" className="icon" />
                </div>
                <div className="right">
                  <h5 className="head">As we continue to grow:</h5>
                  <p className="para">
                    we remain focused on our core values of trust, reliability,
                    and customer satisfaction. We are proud of the relationships
                    we have built with our customers.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="right-c">
            <img src={backImg} alt="Car Recovery Service Near Me Kolkata" className="imgssss" />
          </div>
        </div>
      </div>

      <div className="our-services-container">
        <div className="our-service">
          <div className="items-box">
            <img src={mission} alt="Car Tochan Service In Ahmedabad" className="icon" />
            <div className="box-items">
              <div className="head">Our Mission</div>
              <p className="para">
                Roadserve's mission is to provide exceptional 24x7 roadside
                assistance to our valued customers.
              </p>
            </div>
          </div>

          <div className="items-box">
            <img src={vission} alt="Commercial Road Service Near Me Amritsar" className="icon" />
            <div className="box-items">
              <div className="head">Our Vision</div>
              <p className="para">
                Our vision at Roadserve is to become the leading provider of
                roadside assistance services globally.
              </p>
            </div>
          </div>

          <div className="items-box">
            <img src={history} alt="Honda Roadside Assistance In Amritsar" className="icon" />
            <div className="box-items">
              <div className="head">Our History</div>
              <p className="para">
                Roadserve was founded in 2022 with a clear objective: to fill
                the gap in the market.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="app-container">
        <img src={appback} alt="My Car Roadside Assistance In Amritsar" className="appback" />
        <div className="app-container-box">
          <h3 className="head">
            Download Our App for <br /> Any Devices:
          </h3>
          <a
            href="https://play.google.com/store/apps/details?id=com.roadserve.roadservepanel"
            className="button"
          >
            <BsGooglePlay className="icons" /> Download for Android
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Abouts;
