import React, { useState } from "react";

function NavContainer(props) {
  const [itemName, setItemName] = useState("All Blog");
  const [items, setItems] = useState([
    "All Blog",
    "Add Post",
    "Category & Tags",
    "Logout",
  ]);
  
  return (
    <div className="nav-container">
      {items.map((item, index) => (
        <p className={itemName === item ? "item-match" : "items"} onClick={() => {
          props.setBord(item)
          setItemName(item);
        }
        } key={index}>
          {item}
        </p>
      ))}
    </div>
  );
}

export default NavContainer;
