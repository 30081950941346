import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import './Question.scss';
import {questions} from '../../../../Datas/Jumpstart';
function Question() {
    return (
        <div className='question-container'>
            <div className="heading-container">
                <h3 className="heading">Facts & Questions</h3>
            </div>

            <div className="container-box-for-questions">
                <Accordion className='items-container'>
                    {
                        questions.map((item, index) => (
                            <Accordion.Item eventKey={index} className='item-box' key={index}>
                                <Accordion.Header>{item.que}</Accordion.Header>
                                <Accordion.Body>{item.ans}</Accordion.Body>
                            </Accordion.Item>
                        ))
                    }
                </Accordion>
            </div>
        </div>
    )
}

export default Question