import React from 'react';
import Slider from '../SliderContainer/Slider';

function Works() {

    return (
        <div className="works-container">
            <div className="header-container">
                <h4 className="head">Popular Blogs</h4>
                <div className="blank-border"></div>
            </div>
            <div className="item-div-container">
                <Slider />
            </div>

        </div>
    )
}

export default Works