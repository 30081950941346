import React, { useContext, useEffect } from 'react';
import { Storage } from '../../App';
import { useNavigate } from 'react-router-dom';
import Pricing from '../PagesComponents/Pricing/Pricing';
import { Helmet } from "react-helmet";

function PricingPage() {
  const navigate = useNavigate();
  const { postUserlog, getPage } = useContext(Storage);

  useEffect(() => {
    if (postUserlog) {
      navigate("/user/home");
    }
    getPage("Pricing");
  }, []);

  return (
    <>
      <Helmet>
        <title>Best Roadside Assistance Company In Mumbai, On Road Car Service In Mumbai</title>
        <meta name="description" content="Best Roadside Assistance Company In Mumbai To assist you in resolving your automotive issues, we offer you qualified professionals and expert mechanics in Mumbai. Contact Us if you require any other information." />
        <meta name="keywords" content="best roadside assistance company in Mumbai, on road car service in Mumbai, best roadside assistance in Mumbai, car breakdown service in Mumbai, car breakdown service near me Mumbai" />
        <meta name='keyphrase' content="Car Recovery Service Near Me Mumbai, Car Road Service Near Me Mumbai" />
        <meta name='keyphrase' content="We are a 24 Hour Car Recovery Service Near Me Mumbai with full responsibility for any type of damage to all four-wheeled cars. We are available around-the-clock, seven days a week." />
        <meta name="serp-rank" position="1" />
        <meta name="description" content="Ridcor" />
        <meta name="keywords" content="Ridcor" />
        <meta name="revisit-after" content="7 days" />
        <meta name='Author' content="https://roadserve.in" />
        <meta name='Copyright' content="Road Serve" />
        <meta name='rating' content="general" />
        <meta name='distribution' content="global" />
        <meta property="og:title" content="My Car Roadside Assistance In Mumbai, On Road Car Assistance In Mumbai" />
        <meta property='og:type' content="website" />
        <meta property="og:description" content="Road Serve is My Car Roadside Assistance In Mumbai, When you need us, we'll be here with creative answers to all of your problems. We provide roadside assistance packages for our four wheelers." />
        <meta property="og:image" content="https://roadserve.in/static/media/Pricing-head.39dd28f605126e03ef8d.png" />
        <meta property="og:url" content="https://roadserve.in" />
        <link rel="canonical" href="https://roadserve.in" />
        <meta property="og:site_name" content="Road Serve" />
        <meta itemprop='name' content="Road Side Recovery In Mumbai, Road Side Service In Mumbai" />
        <meta itemprop='description' content="For Road Side Recovery In Mumbai, we aim to have a crew with a solution ready to go on the scene within 20 to 30 minutes. Towing, on-site repairs, lost or locked keys, incorrect or low gasoline, and batteries." />
        <meta itemprop='url' content="https://roadserve.in" />
      </Helmet>
      <div className="hide-text" style={{display : 'none'}}>
          <h1>24 7 Roadside Assistance In Mumbai</h1>
          <h1>Car Maintenance Near Me Kolkata</h1>
          <h1>Car Mechanic Near Me 24 Hours In Kolkata</h1>
          <h1>Car Mechanic Near Me Kolkata</h1>
          <h1>Car Mechanic Near Me Home Service In Kolkata</h1>
          <h1>Car Recovery Service Near Me Kolkata</h1>
          <h1>Car Road Service Near Me Ahmedabad</h1>
        </div>
      <Pricing />
    </>
  )
}

export default PricingPage;