import React, { useContext, useEffect } from 'react';
import { Storage } from '../../App';
import { useNavigate } from 'react-router-dom';
import Abouts from '../PagesComponents/About/Abouts';
import { Helmet } from "react-helmet";


function AboutPage() {
  const navigate = useNavigate();
  const { postUserlog, getPage } = useContext(Storage);
  useEffect(() => {
    if (postUserlog) {
      navigate("/user/home");
    }
    getPage('About')
  }, []);
  return (
    <>
      <Helmet>
        <title>24/7 Roadside Assistance Near Me Mumbai | Car Tochan Service In Mumbai</title>
        <meta name="description" content="In the event of a vehicle breakdown on the road, anywhere in 24 7 Roadside Assistance Near Me Mumbai, we provide and guarantee extremely quick assistance by qualified auto experts." />
        <meta name="keywords" content="24/7 Roadside Assistance Near Me Mumbai, Car Tochan Service In Mumbai, 24x7 roadside assistance near me Mumbai, best car repair near me Mumbai, best mechanic near me Mumbai" />
        <meta name='keyphrase' content="Car Mechanic Near Me 24 Hours In Mumbai, Car Mechanic Near Me Mumbai" />
        <meta name='keyphrase' content="We provide a variety of Car Mechanic Near Me 24 Hours In Mumbai to our clients and take the utmost care of your car while it is being repaired. We provide the most reasonable vehicle service available on the market." />
        <meta name="serp-rank" position="1" />
        <meta name="description" content="Ridcor" />
        <meta name="keywords" content="Ridcor" />
        <meta name="revisit-after" content="7 days" />
        <meta name='Author' content="https://roadserve.in" />
        <meta name='Copyright' content="Road Serve" />
        <meta name='rating' content="general" />
        <meta name='distribution' content="global" />
        <meta property="og:title" content="Emergency Roadside Assistance In Mumbai, Honda Roadside Assistance In Mumbai" />
        <meta property='og:type' content="website" />
        <meta property="og:description" content="Anytime you require our Emergency Roadside Assistance in Mumbai, simply contact our helpline or reserve our emergency roadside assistance in Mumbai services using our app. Ring right away." />
        <meta property="og:image" content="https://roadserve.in/static/media/about-imgs.130e3dbc5296bb68d7a7.png" />
        <meta property="og:url" content="https://roadserve.in" />
        <link rel='canonical' href="https://roadserve.in" />
        <meta property="og:site_name" content="Road Serve" />
        <meta itemprop='name' content="Road Assistance Service Near Me Mumbai, Road Side Near Me Mumbai " />
        <meta itemprop='description' content="Searching for Local Road Assistance Service Near Me Mumbai? So you're in the proper spot. We provide a variety of auto repair services to our clients and treat your car." />
        <meta itemprop='url' content="https://roadserve.in" />
      </Helmet>
      <div className="hide-text" style={{ display: 'none' }}>
        <h1>24 7 Roadside Assistance In Mumbai</h1>
        <h1>24 7 Roadside Assistance Near Me Mumbai</h1>
        <h1>24 Breakdown Service In Mumbai</h1>
        <h1>Best Car Repair Near Me Mumbai</h1>
        <h1>Best Mechanic Near Me Pune</h1>
        <h1>Best Roadside Assistance Company In Pune</h1>
        <h1>Best Roadside Assistance In Pune</h1>
        <h1>Car Breakdown Service In Pune</h1>
      </div>
      <Abouts />
    </>

  );
}

export default AboutPage;
