
import rahulimg from '../Images/ReviewsImg/Rahulsmall.png';
import komal from '../Images/ReviewsImg/komol.jpg';
import Nidhi from '../Images/ReviewsImg/nidhi.jpg';
import himanshu from '../Images/ReviewsImg/himanshu.jpg';
import Ajay from '../Images/ReviewsImg/person1.jpg';
import abhinav from '../Images/ReviewsImg/person2.jpg';


export const reviewList = [
    {
        id : 0,
        review : "RoadServe provided reliable and professional help when my car had a breakdown. Their service provider arrived within 30 minutes and efficiently resolved the issue. Highly recommended!",
        client_name:"Rahul Biswas",
        client_ocopations:"Software Engineer",
        client_img : rahulimg,
    },
    {
        id : 1,
        review : "Lifesaver on the Road! I can't express how grateful I am for RoadServe! This app has truly been a lifesaver on multiple occasions. The 24x7 roadside assistance they provide is incredibly efficient.",
        client_name:"Himanshu Gupta",
        client_ocopations:"Businessman",
        client_img : himanshu,
    },
    {
        id : 2,
        review : "The Ultimate Roadside Companion! RoadServe has become my go-to app for any roadside emergencies. It's like having a personal guardian angel for my car! I've used this app several times.",
        client_name:"Komal Bisht",
        client_ocopations:"HR Executive",
        client_img : komal,
    },
    {
        id : 3,
        review : "I can't express how grateful I am for RoadServe! This app has truly been a lifesaver on multiple occasions. Whether it's a flat tire, a dead battery, or even running out of fuel.",
        client_name:"Nidhi Parcha",
        client_ocopations:"Teacher",
        client_img : Nidhi,
    },
    {
        id : 4,
        review : "RoadServe has become my trusted companion on the road. Their service is dependable and efficient. Whether it's a mechanical issue or a minor breakdown, RoadServe is quick to respond.",
        client_name:"Ajay Kumar",
        client_ocopations:"CA",
        client_img : Ajay,
    },
    {
        id : 5,
        review : "Top-notch Road Service It is abundantly clear that your team is comprised of highly skilled professionals. I have always felt confident in the capabilities of your technicians. Great Work",
        client_name:"Abhinav Dwivedi",
        client_ocopations:"Writer",
        client_img : abhinav,
    }
]