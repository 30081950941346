import React from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { AdminLog_URL , AdminRegister_URL} from "../../API/API";
import { useNavigate } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

function createNotification(type) {
  switch (type) {
    case 'info':
      NotificationManager.info('Info message', 'Register Successfully ! please Login', 3000);
      break;
    case 'success':
      NotificationManager.success('Success message', 'Login Successfully', 3000);
      break;
    case 'warning':
      NotificationManager.warning('Warning message', 'Enter Valid Data', 3000);
      break;
    case 'error':
      NotificationManager.error('Error message', 'Sorry! Try Again', 3000);
      break;
  }
};

function LoginBox(props) {
  const navigate = useNavigate();

  const login_btn_handler = async () => {
    const { value: email } = await Swal.fire({
      title: "Input email address",
      input: "email",
      inputLabel: "Your email address",
      inputPlaceholder: "Enter your email address",
    });

    if (email) {
      const { value: password } = await Swal.fire({
        title: "Enter your password",
        input: "password",
        inputLabel: "Password",
        inputPlaceholder: "Enter your password",
        inputAttributes: {
          maxlength: 10,
          autocapitalize: "off",
          autocorrect: "off",
        },
      });
      if (password) {
        axios
          .post(`${AdminLog_URL}username=${email}&password=${password}`)
          .then((res) => {
            // console.log("admin log :", res);
            props.setTokens(res.data.token);
            localStorage.setItem("admin_token", res.data.token)
            createNotification('success')
          })
          .catch((err) => {
            console.log("Log err : ", err);
            createNotification('error')
          });
      }
    }
  };

  const Singup_handler = async () => {
    const { value: email } = await Swal.fire({
      title: "Input email address",
      input: "email",
      inputLabel: "Your email address",
      inputPlaceholder: "Enter your email address",
    });

    if (email) {
      const { value: password } = await Swal.fire({
        title: "Enter your password",
        input: "password",
        inputLabel: "Password",
        inputPlaceholder: "Enter your password",
        inputAttributes: {
          maxlength: 10,
          autocapitalize: "off",
          autocorrect: "off",
        },
      });
      if (password) {
        axios
          .post(`${AdminRegister_URL}username=${email}&password=${password}`)
          .then((res) => {
            createNotification('info');
          })
          .catch((err) => {
            console.log("Log err : ", err);
            createNotification('error')
          });
      }
    }
  }
  return (
    <div className="login-container">
      <button onClick={() => navigate('/')}>Back</button>
      <h3 className="blogs">You Are Admin ? Please Login!</h3>
      <button onClick={login_btn_handler}>Login</button>
      <button onClick={Singup_handler}>Singup</button>
      <NotificationContainer />
    </div>
  );
}

export default LoginBox;
