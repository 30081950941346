import React, { useState } from "react";
import "./WelllcomePopUp.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SendMail_URL } from "../../../API/API";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
const schema = yup
  .object({
    name: yup.string().required("Name is a required field"),
    email: yup.string().required("Email is a required field"),
    phone: yup.string().required("Number is a required field"),
  })
  .required();

function WelllcomePopUp(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (data.phone.length === 10) {
      setLoading(true);
      axios
        .post(SendMail_URL, {
          name: data.name,
          phone: data.phone,
          email: data.email,
          subject: "New Contact",
          message: "Please Contact with me",
        })
        .then((res) => {
          localStorage.setItem("Message", "Done");
          reset();
          setLoading(false);
          props.getWlcCls("wellcome-hide");
          navigate("/thank-you");
        })
        .catch((err) => {
          console.log("Subscribe err : ", err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: "try Again",
          });
          props.recStatus("focus-hide");
        });
    } else {
      alert("Type Valid Mobile Number");
    }
  };

  return (
    <div className={props.postWlcCls}>
      <div className="wellcome-container">
        <h3 className="heading">Subscribe to our Newsletter</h3>
        <p className="para">
          Be the first to know about the latest plans & offers
        </p>
        <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            placeholder="Your Name"
            className="input-field"
            name="name"
            {...register("name")}
          />
          <input
            type="email"
            placeholder="Your Email"
            className="input-field"
            name="email"
            {...register("email")}
          />
          <input
            type="number"
            placeholder="Your Number"
            className="input-field number"
            name="phone"
            {...register("phone")}
          />
          {/* <button className="button" type="submit">Send</button> */}
          {loading ? (
            <button className="button-loder">
              Sending
              {
                <ThreeDots
                  radius="3"
                  color="#fff"
                  visible={true}
                  height="20"
                  width="50"
                />
              }
            </button>
          ) : (
            <button className="button">Send</button>
          )}
        </form>

        <CloseIcon
          className="close-icon"
          onClick={() => props.getWlcCls("wellcome-hide")}
        />
      </div>
    </div>
  );
}

export default WelllcomePopUp;
