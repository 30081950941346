import React, { useContext, useEffect } from 'react';
import { Storage } from '../../App';
import { useNavigate } from 'react-router-dom';
import Contact from '../PagesComponents/Contact/Contact';
import { Helmet } from "react-helmet";

function ContactPage() {
  const navigate = useNavigate();
  const { postUserlog, getPage } = useContext(Storage);
  useEffect(() => {
    if (postUserlog) {
      navigate("/user/home");
    }
    getPage('Contact')
  }, []);
  return (
    <div style={{ width: '100%', marginTop: '100px' }}>
      <Helmet>
        <title>Best Roadside Assistance In Mumbai | Car Breakdown Service In Mumbai</title>
        <meta name="description" content="Best Roadside Assistance In Mumbai, we provide solutions for mechanical issues, battery starts, fuel issues, punctures, key locks, and towing if necessary. Get APP Right Now" />
        <meta name="keywords" content="Best Roadside Assistance In Mumbai, Car Breakdown Service In Mumbai, Car Breakdown Service Near Me Mumbai, Car Maintenance Near Me Mumbai, Car Mechanic Near Me Mumbai" />
        <meta name='keyphrase' content="Car Roadside Assistance In Mumbai, Car Service Center Near Me Mumbai" />
        <meta name='keyphrase' content="For Car Roadside Assistance In Mumbai, we provide solutions in the event of mechanical issues, dead batteries, failed starts, fuel issues, tire blowouts, key locks, and towing if necessary." />
        <meta name="serp-rank" position="1" />
        <meta name="description" content="Ridcor" />
        <meta name="keywords" content="Ridcor" />
        <meta name="revisit-after" content="7 days" />
        <meta name='Author' content="https://roadserve.in" />
        <meta name='Copyright' content="Road Serve" />
        <meta name='rating' content="general" />
        <meta name='distribution' content="global" />
        <meta property="og:title" content="On Road Service Near Me Mumbai, Recovery Van Near Me In Mumbai" />
        <meta property='og:type' content="website" />
        <meta property="og:description" content="Our crew will send a mechanic to you if you phone our helpline or book our emergency roadside assistance in Mumbai services using our app. We offer On Road Service Near Me Mumbai." />
        <meta property="og:image" content="https://roadserve.in/static/media/maps.d8dd1ac20d50cb8f2b90.png" />
        <meta property="og:url" content="https://roadserve.in" />
        <link rel='canonical' href="https://roadserve.in" />
        <meta property="og:site_name" content="Road Serve" />
        <meta itemprop='name' content="Road Side Services Near Me Mumbai, Roadside Assistance App In Mumbai" />
        <meta itemprop='description' content="Our business can offer consumers top-notch luxury car care packages because to its years of experience in offering automobile tuning, maintenance, and Road Side Services Near Me Mumbai." />
        <meta itemprop='url' content="https://roadserve.in" />
      </Helmet>
      <div className="hide-text" style={{ display: 'none' }}>
        <h1>Best Roadside Assistance Company In Pune</h1>
        <h1>Best Roadside Assistance In Pune</h1>
        <h1>Car Breakdown Service In Pune</h1>
        <h1>Car Breakdown Service Near Me Pune</h1>
        <h1>Car Maintenance Near Me Kolkata</h1>
        <h1>Car Service Center Near Me Ahmedabad</h1>
        <h1>Car Tochan Service In Ahmedabad</h1>
        <h1>Car Towing Service Near Me Ahmedabad</h1>
        <h1>Commercial Road Service Near Me Amritsar</h1>
        <h1>Emergency Roadside Assistance In Amritsar</h1>
        <h1>Honda Roadside Assistance In Amritsar</h1>
        <h1>Hyundai Road Side Assistance In Amritsar</h1>
      </div>
      <Contact />
    </div>
  )
}

export default ContactPage;