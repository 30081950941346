import React, { useContext } from "react";
import logo from "../../../Images/NavbarLogo.png";
import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Subscribe_URL } from "../../../API/API";
import axios from "axios";
import Swal from "sweetalert2";
import './Footersection.scss';
import { useNavigate } from "react-router-dom";
import { Storage } from "../../../App";

const schema = yup
  .object({
    email: yup.string().required("Email is a required field!"),
  })
  .required();


function Footer() {
  const date = new Date();
  const year = date.getFullYear();
  const navigate = useNavigate();
  const { getServiceId } = useContext(Storage);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    axios.post(`${Subscribe_URL}${data}`)
      .then((res) => {
        navigate('/thank-you')
        reset();
      })
      .catch((err) => {
        console.log("Subscribe err : ", err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: "try Again",
        });
      });
  };


  return (
    <div className="footer-components">
      <div className="footer-container">
        <div className="left-container">
          <img src={logo} alt="24 7 Roadside Assistance In Mumbai" />
          <div className="details">
            <p className="peragraph">
              Roadserve is a RoadSide Assisstance Company which provides
              Road-side Assistance to it's users anywhere in PAN India 24X7
            </p>
            <br />
            <div className="address">
              <div className="items">
                <p className="head">A UNIT OF RISEGRIT AUTOMOBILE SERVICES PRIVATE LIMITED</p>
              </div>
              <div className="items">
                <p className="head">Head Office : </p>
                <p className="pera">3rd floor 2151/9b shadipur, patel nagar, New Delhi, Pin- 110008.</p>
              </div>
              <div className="items">
                <p className="head">Branch : </p>
                <p className="pera">401 Shagun tower 4th floor , Vijay nagar square, Indore, MP.</p>
              </div>
            </div>
            <div className="logos">
              <a href="https://www.facebook.com/RoadServeIndia"
                target="_blank" rel="noreferrer"
              >
                <BsFacebook className="icons" />
              </a>
              <a href="https://twitter.com/RoadServeIndia" target="_blank" rel="noreferrer">
                <BsTwitter className="icons" />
              </a>
              <a href="https://www.instagram.com/roadserveindia/"
                target="_blank" rel="noreferrer"
              >
                <BsInstagram className="icons" />
              </a>
            </div>
          </div>
        </div>
        <div className="right-container">
          <div className="left">
            <div className="about-us">
              <table>
                <tr>
                  <th>Quick Links</th>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <a href="/about">About Us</a>
                      </li>
                      <li>
                        <a href="#works">How Does it Work</a>
                      </li>
                      <li>
                        <a href="/pricing">Pricing</a>
                      </li>
                      <li>
                        <a href="/get-call">Get a Call Back</a>
                      </li>
                      <li>
                        <a href="/terms-condition">Terms & conditions</a>
                      </li>
                      <li>
                        <a href="https://roadserve.in/policy.html">App Privacy Policy</a>
                      </li>
                      <li>
                        <a href="https://roadserve.in/refund.html">Refund & Cancellation</a>
                      </li>
                    </ul>
                  </td>
                </tr>
              </table>
            </div>
            <div className="popular-service">
              <table>
                <tr>
                  <th>Popular Services</th>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <a href="/service">Road Assisstance</a>
                      </li>
                      <li>
                        <a href="/service">Fuel Delivery</a>
                      </li>
                      <li>
                        <a href="/service">Towing Service</a>
                      </li>
                      <li>
                        <a href="/service">Jump Start Week</a>
                      </li>
                      <li>
                        <a href="/service">Punctured Tyre</a>
                      </li>
                      <li>
                        <a href="/service">Discounted Parts</a>
                      </li>
                      <li>
                        <a href="/service">Dedicated Support</a>
                      </li>
                    </ul>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="right">
            <div className="subscribe">
              <table>
                <tr>
                  <th>Subscribe</th>
                </tr>
              </table>
              <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <label>YOUR EMAIL</label>
                <div className="input">
                  <input
                    type="text"
                    name="email"
                    className="input-box"
                    placeholder="Enter Your Email Address"
                    {...register("email")}
                  />
                  <p>{errors.email?.message}</p>
                </div>
                <button type="submit">Subscribe</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-box">
        <p>Made with &#10084; in India </p>
        <p>© {year} ROADSERVE ALL RIGHTS RESERVED</p>
      </div>
    </div>
  );
}

export default Footer;
