import React from 'react';
import './Container.scss';
import {GiFlatTire, GiMechanicGarage, GiCarBattery} from "react-icons/gi";
import {BiSolidCarGarage} from 'react-icons/bi';

function Service() {
  return (
    <div className="works-components third-con" id="works">

      <div className="work-container">
        <div className="container-head">
          <p className="head">Why you should choose Road Serve APP?</p>
          <h3 className="tags">Take a look at our quality features</h3>
          <p className="pera">
            Use the Road Serve app since it has great functions. Quick and dependable jump starts, flat tire replacements, and towing services are all available via our app. Our 24/7 availability, intuitive UI, and precise tracking provide for a stress-free emergency experience. Have faith in the Road Serve APP to get you back on the road in a timely and secure manner.
          </p>
        </div>
      </div>

      <div className="blank"></div>

      <div className="work-container-body work-con">
        <div className="body-container">

          <div className="items-1 item-box">
            <div className="box-icon">
              <span className="count">1</span>
              <GiCarBattery className="icons" />
            </div>

            <p className="item-head">Jump Start Service</p>
            <p className="item-details">Roadserve offers battery jumpstart services.</p>
          </div>

          <div className="items-2 item-box">
            <div className="box-icon">
              <span className="count">2</span>
              <GiFlatTire className="icons" />
            </div>
            <p className="item-head">Flat Tire Replacements</p>
            <p className="item-details">Roadserve provides tire assistance.</p>
          </div>

          <div className="items-3 item-box">
            <div className="box-icon">
              <span className="count">3</span>
              <BiSolidCarGarage className="icons" />
            </div>
            <p className="item-head">Towing Services</p>
            <p className="item-details">Roadserve offers reliable towing services.</p>
          </div>

          <div className="items-4 item-box">
            <div className="box-icon">
              <span className="count">4</span>
              <GiMechanicGarage className="icons" />
            </div>
            <p className="item-head">24/7 Availability</p>
            <p className="item-details">Roadserve offers comprehensive 24x7 roadside assistance.</p>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Service