import React from "react";
import "./ServiceType.scss";
// import img1 from "../../../../Images/Background/about-imgs.png";
import road1 from '../../../../Images/images/Roadside.png';
import road2 from '../../../../Images/images/fuel.jpg';
import road3 from '../../../../Images/images/TowingServices.jpg';
import road4 from '../../../../Images/images/Jump-Start-Cost-Who-To-Call.jpg';
import road5 from '../../../../Images/images/Flat Tire.jpg';
import road6 from '../../../../Images/images/Discounted Parts.jpg';
import road7 from '../../../../Images/images/Car or Suv.avif';
import road8 from '../../../../Images/images/Dedicated Support.png';


function ServiceContainer(props) {
  const boxitem = [
    {
      id: 1,
      head: "Roadside Assistance",
      pera: "Roadserve offers comprehensive 24x7 roadside assistance to help you overcome unexpected car troubles. Whether you have a flat tire, a drained battery, or any other issue, our experienced professionals are just a call away. We provide prompt on-site assistance, ensuring your safety and getting you back on the road as quickly as possible.",
      class: "icon1",
      img: road1,
    },
    {
      id: 2,
      head: "Fuel Delivery",
      pera: "Running out of fuel is an inconvenience that can leave you stranded. Roadserve offers fuel delivery services to ensure you never get stuck due to low fuel. Simply contact us, and our team will deliver the fuel you need to get you back on track.",
      class: "icon2",
      img: road2,
    },
    {
      id: 3,
      head: "Towing Services",
      pera: "In the event of a major breakdown or accident, Roadserve offers reliable towing services. Our professional towing partners will transport your vehicle to the nearest authorized service center or your preferred location, ensuring it receives the necessary repairs.",
      class: "icon3",
      img: road3,
    },
    {
      id: 4,
      head: "Battery Jumpstart",
      pera: "If your car's battery dies and you're unable to start your vehicle, Roadserve offers battery jumpstart services. Our technicians will arrive at your location equipped with the necessary tools and expertise to jumpstart your battery and get you moving again.",
      class: "icon4",
      img: road4,
    },
    {
      id: 5,
      head: "Flat Tire Assistance",
      pera: "Dealing with a flat tire can be frustrating, especially when you're on the go. Roadserve provides tire assistance, including tire changing and repair services. Our team will arrive at your location, assess the tire's condition, and either repair it or replace it with a spare tire, ensuring you can continue your journey safely.",
      class: "icon5",
      img: road5,
    },
    {
      id: 6,
      head: "Discounted Parts",
      pera: "As a valued Roadserve customer, you gain access to exclusive discounts on a wide range of car parts and accessories. Enjoy discounted prices while ensuring your vehicle receives high-quality components, saving you money on repairs and upgrades.",
      class: "icon6",
      img: road6,
    },
    {
      id: 7,
      head: "Car or Suv",
      pera: "Roadserve makes it easy for you to get the expert auto assistance you need. Whether you drive a car or a SUV, we offer extraordinary services on the road.",
      class: "icon7",
      img: road7,
    },
    {
      id: 8,
      head: "Dedicated Support",
      pera: "Our team is equipped with expert vehicle technicians and helpful customer service agents, who are highly trained in their profession and are also equipped with world class tools.",
      class: "icon8",
      img: road8,
    },
  ];
  return (
    <div className="ServiceContainer">
      <div className="container-box">
        <h3 className="head">{boxitem[props.id].head}</h3>
        <p className="para">{boxitem[props.id].pera}</p>
        <img src={boxitem[props.id].img} alt={boxitem[props.id].head} className="images" />
      </div>
    </div>
  );
}

export default ServiceContainer;
