import React, { useEffect, useState, useContext } from 'react';
import './VerifyDetails.scss';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { verifyDetails_URL } from '../../../API/API';
import axios from 'axios';

const detailsSchema = yup.object({
  name: yup.string().required(),
  number: yup.string().required(),
  address: yup.string().required(),
  pin: yup.string().required(),
  state: yup.string().required(),
  car_model: yup.string().required(),
  car_number: yup.string().required(),
}).required();

function VerifyDetails() {
  const navigate = useNavigate();
  const param = useParams();
  const numbers = param.number.split('=')[1];
  const [spinner, setSpinner] = useState(false);
  const [nameVal, setNameVal] = useState();
  const [number, setNumber] = useState(numbers);
  const [addressVal, setAddressVal] = useState();
  const [car_modelVal, setCar_ModelVal] = useState();
  const [car_numVal, setCar_numVal] = useState();
  const [stateVal, setStateVal] = useState();
  const [pinVal, setPinVal] = useState();
  const [plan, setPlan] = useState();
  const [amount, setAmount] = useState();
  const [id, setId] = useState();

  useEffect(() => {
    if (param.details) {
      const dtls = param.details.split('=')[1];
      if (dtls === 'true') {
        const userData = JSON.parse(localStorage.getItem('coustomer_data'));
        setNameVal(userData.name);
        setAddressVal(userData.address);
        setPinVal(userData.pin_code);
        setCar_ModelVal(userData.car_model);
        setStateVal(userData.state);
        setCar_numVal(userData.car_number);
        setNumber(userData.contact_number)
        setId(userData._id.$oid)
      } else {
        const num = param.number.split('=')[1];
        setNumber(num)
      }
    }
    if (param.plan) {
      const plan = param.plan.split('=')[1];
      setPlan(plan)
      if (plan === "light") {
        setAmount('999');
      } else if (plan === "basic") {
        setAmount('1999');
      } else if (plan === "advance") {
        setAmount('3999');
      } else if (plan === "ultimate") {
        setAmount('4999');
      }
    }
  }, [])

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(detailsSchema)
  });


  const onSubmit = (data) => {
    setSpinner(true)
    const Token = localStorage.getItem('token_plans_log');
    const AllData = {
      name: data.name,
      car_number: data.car_number,
      car_model: data.car_model,
      contact_number: data.number,
      address: data.address,
      pin_code: data.pin,
      state: data.state,
      method: 'cod',
      plan: plan,
      amount: amount,
      id : id,
    }
    const config = {
      headers: {
        'Authorization': `Bearer ${Token}`
      }
    }
    axios.post(verifyDetails_URL, AllData, config)
      .then(res => {
        // console.log('res : ', res)
        localStorage.setItem("payment_details", JSON.stringify(res.data))
        localStorage.setItem('coustomer_data', JSON.stringify(AllData));
        setSpinner(false);
        navigate('/payment/status=true');
      })
      .catch(err => {
        alert("Opps...! Sorry, Please Try Again")
        setSpinner(false);
        console.log("Details er :", err);
      })
  };


  return (
    <div className="main-div">
      <div className='details-container'>
        <div className="details-box">

          <div className="form-header">
            <h3 className="heading">Please fill your details</h3>
          </div>


          <form onSubmit={handleSubmit(onSubmit)} className='form-container'>
            <input {...register("name")} name='name' type="text" placeholder='Your Full Name' value={nameVal} onChange={(e) => setNameVal(e.target.value)} className='input-field' />
            <input {...register("number")} name='number' type="number" placeholder='Your Number' className='input-field' value={number} />
            <textarea {...register("address")} name="address" placeholder='Your Address' value={addressVal} onChange={(e) => setAddressVal(e.target.value)} className='text-area'></textarea>

            <div className="flex-div">
              <input {...register("pin")} name="pin" type="number" placeholder='Pin' className='flex-input' value={pinVal} onChange={(e) => setPinVal(e.target.value)} />
              <input {...register("state")} name="state" type="text" placeholder='State' className='flex-input' value={stateVal} onChange={(e) => setStateVal(e.target.value)} />
            </div>

            <div className="flex-div">
              <input {...register("car_model")} name="car_model" type="text" placeholder='Car Model' className='flex-input' value={car_modelVal} onChange={(e) => setCar_ModelVal(e.target.value)} />
              <input {...register("car_number")} name="car_number" type="text" placeholder='Car Number' className='flex-input' value={car_numVal} onChange={(e) => setCar_numVal(e.target.value)} />
            </div>

            <button type='submit' className='btn'>{spinner ? 'Sending...' : 'Continue'}</button>

          </form>

        </div>

      </div>
    </div>
  )
}

export default VerifyDetails